import React, { useState, useRef, useEffect, useCallback } from "react";

import Container from "../container/container";
import { useLocation, useParams } from "react-router-dom";
import RiderHeading from "../rider/riderheading/riderHeading";
import Orders from "./orders";
import { singleRider, successfulOrders } from "../../Utils/api";
import { useSelector } from "react-redux";
const CompletedDeliveries = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const [isExit, setisExit] = useState(false);
  const observeEl = useRef();
  const { token } = useSelector((state) => state.user);
  const [, setrider] = useState();
  const [data, setdata] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [nextPageLoad, setNextPageLoad] = useState(false);
  const [isEmpty, setisEmpty] = useState(false);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [initialstart, setinitialstart] = useState("");
  const [initialending, setinitialending] = useState("");
  ///2023-06-25

  console.log("start date", startDate);
  console.log("end date", endDate);

  useEffect(() => {
    if (initialending !== endDate || initialstart !== startDate) {
      setPage(1);
    }

    const payload = {
      period: startDate && endDate ? "custom" : "",
      startDate,
      endDate,
    };
    async function getAllOrders() {
      if (isEmpty) return;
      if (page === 1) {
        setLoading(true);
      } else {
        setNextPageLoad(true);
      }
      await successfulOrders(id, payload, page, token)
        .then((res) => {
          console.log(res);
          setLoading(false);
          setNextPageLoad(false);
          setinitialstart(startDate);
          setinitialending(endDate);
          const { items } = res.data.data;
          if (items.length === 0) {
            setisEmpty(true);
            return;
          }
          setdata((prev) => [...prev, ...items]);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    getAllOrders();
  }, [
    page,
    startDate,
    endDate,
    initialending,
    initialstart,
    isEmpty,
    id,
    token,
  ]);
  const totalamount = data?.reduce((acc, curr) => acc + curr.total || 0, 0);

  /// Get the exiting element
  const firstElement = useCallback((node) => {
    observeEl.current = new IntersectionObserver((entries) => {
      if (!entries[0].isIntersecting) {
        console.log("not visible");
        setisExit(true);
      } else {
        setisExit(false);
      }
    });

    if (node) observeEl.current.observe(node);
  }, []);

  useEffect(() => {
    async function getSingleRider() {
      await singleRider(id, token)
        .then((res) => {
          console.log(res);
          const { data } = res.data;
          const { rider } = data;
          setrider(rider);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getSingleRider();
  }, [id, token]);
  return (
    <Container>
      <div className="w-full h-full">
        <div className="flex justify-start items-center">
          <p className="font-semibold text-lg ">Rider</p>
        </div>
        <div className="py-3 px-6 mb-[50px] flex flex-col bg-white rounded-md  w-full h-fit">
          <RiderHeading
            isExit={isExit}
            name={state?.name}
            firstElement={firstElement}
            image={""}
          />
          <Orders
            id={"S/N"}
            title={"Food Title"}
            data={data}
            page={page}
            totalTransactions={data?.length || 0}
            setPage={setPage}
            isEmpty={isEmpty}
            totalamount={totalamount || 0}
            loading={loading}
            setstartDate={setstartDate}
            setendDate={setendDate}
            nextPageLoad={nextPageLoad}
          />
        </div>
      </div>
    </Container>
  );
};

export default CompletedDeliveries;
