/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import SalesWidget from "../saleswidget/salesWidget";
import { recentSales } from "../../../../Utils/api";
import empty from "../../../../assets/png/emptyorder.png";
import loadings from "../../../../assets/svg/loading.svg";
import { useLatestSalesHook } from "Hooks";
import { LoaderIcon } from "react-hot-toast";
import { getNairaEquivalent } from "Utils/lib/helper";
import moment from "moment";
const DailySales = ({ active }) => {
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [paging, setPaging] = useState("");
  const [nextPageLoad, setnextPageLoad] = useState(false);
  //console.log(utcToday)

  useLatestSalesHook(
    recentSales,
    "daily",
    page,
    setdata,
    setPaging,
    setLoading,
    setnextPageLoad
  );

  return (
    <div className={` ${active === 0 ? "" : "hidden"}`}>
      {loading && (
        <div className="w-full h-[300px] flex justify-center items-center">
          <img src={loadings} alt="" />
        </div>
      )}
      {!loading && data?.length === 0 && (
        <div className="w-full h-[300px] flex justify-center items-center">
          <span className="w-[200px] h-[200px]">
            <img className="w-full h-full" src={empty} alt="" />
          </span>
        </div>
      )}
      {!loading && data?.length !== 0 && (
        <div>
          {Array.isArray(data) &&
            data?.map(
              (
                {
                  vendor,
                  meals,
                  packages,
                  deliveryInfo,
                  discount,
                  total,
                  payment,
                  status,
                  createdAt,
                  rider,
                  statusUpdate,
                },
                idx
              ) => {
                console.log(statusUpdate);
                return (
                  <div key={idx}>
                    <SalesWidget
                      id={idx + 1}
                      food={packages[0].items
                        ?.map((item) => item?.product?.name)
                        .toString()}
                      img={vendor?.profileImage?.url}
                      price={getNairaEquivalent(total)}
                      date={moment(createdAt).format("llll")}
                      createdAt={createdAt}
                      payment={payment}
                      orderStatus={status}
                      restaurant={vendor?.businessName}
                      deliveryInfo={deliveryInfo}
                      meals={meals}
                      riderInfo={rider}
                      packages={packages}
                      discount={discount}
                      statusUpdate={statusUpdate}
                    />
                  </div>
                );
              }
            )}
        </div>
      )}
      {paging?.pageCount !== paging?.currentPage && (
        <div className="w-full h-fit mt-4 flex items-center justify-center py-4">
          <button
            onClick={() => {
              setPage(page + 1);
            }}
            className="bg-[#7ba350] rounded-md text-white font-medium flex items-center justify-center h-[36px] w-[150px]"
          >
            {nextPageLoad ? (
              <LoaderIcon className="text-4xl animate-spin" />
            ) : (
              "Show more"
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default DailySales;
