import React, { useState } from "react";
import logo from "../../assets/png/logo.png";
import Loader from "../UI/Loader";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginAction } from "../../Redux/Actions/ActionCreators";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const validateData = {
      email,
      password,
    };

    for (let i in validateData) {
      if (validateData[i] === "") {
        toast.error(`${i} is required`);
        return;
      }
    }

    const payload = {
      email,
      password,
    };
    console.log(payload);
    dispatch(LoginAction(payload, navigate, setLoading));
  };
  return (
    <div className="w-full h-full fixed inset-0 text-zinc-700 bg-white">
      <div className="w-[370px] absolute m-auto h-fit inset-0 p-4 flex space-y-3 flex-col justify-center items-center rounded-lg bg-white border shadow-xl">
        <div className="w-[75px] h-[70px]">
          <img className="w-full h-full " src={logo} alt="" />
        </div>
        <div className="font-semibold">Admin</div>

        <div className="form-group space-y-3 w-full">
          <label className="block font-medium " htmlFor="email">
            Email
          </label>
          <input
            className="block form__input input-field border focus:border-gray-500 hover:border-gray-500 rounded-md focus:outline-none w-full h-11 px-4"
            type="email"
            placeholder=""
            name="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="form-group space-y-3 w-full">
          <label className="block font-medium " htmlFor="password">
            Password
          </label>
          <input
            className="block form__input input-field border focus:border-gray-500 hover:border-gray-500 rounded-md focus:outline-none w-full h-11 px-4"
            type="password"
            placeholder=""
            name="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
        <button
          onClick={handleSubmit}
          className="w-full py-3 bg-[#7BBE4A] rounded-lg text-white flex justify-center items-center space-x-2"
        >
          {loading ? <Loader /> : "Log in"}
        </button>
      </div>
    </div>
  );
};

export default Login;
