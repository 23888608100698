import React, { useState } from "react";
import profile from "../../assets/svg/profile.svg";
import OrderDetail from "./orderDetail";

const OrderWidget = ({
  id,
  name,
  img,
  price,
  meals,
  packages,
  deliveryInfo,
  restaurant,
  date,
  payment,
  orderStatus,
  riderInfo,
  statusUpdate,
}) => {
  console.log(packages);
  const [isdetail, setdetail] = useState(false);
  return (
    <>
      <div className="grid grid-cols-9 w-fit pl-[3px] md:pl-[2%] py-2 border-b gap-2  items-center text-sm">
        <div>{id}</div>
        <div className="grid grid-cols-5 gap-[40px] items-center col-span-2">
          <div className="rounded-full w-[30px] h-[30px]">
            <img
              className="w-full h-full rounded-full"
              src={img || profile}
              alt=""
            />
          </div>
          <div className="col-span-3">
            <div className="font-medium  w-[80px] min-[850px]:w-[130px] lg:w-[120px] xl:w-[130px] text-ellipsis whitespace-nowrap overflow-hidden">
              {name}
            </div>
            <div className="text-gray-500 text-ellipsis xl:w-[130px] overflow-hidden min-[850px]:w-[130px] lg:w-[120px] whitespace-nowrap text-[12px]">
              {restaurant || ""}
            </div>
          </div>
        </div>
        <div>{`₦${price.toLocaleString()}`}</div>
        <div className="capitalize">{payment?.status}</div>
        <div className="capitalize"> {orderStatus}</div>
        <div className="text-gray-600 col-span-2">{date}</div>
        <button
          onClick={() => {
            setdetail(!isdetail);
          }}
          className="font-medium text-white bg-[#7bb43e] py-2 rounded-md min-w-max px-1"
        >
          View Details
        </button>
      </div>
      <OrderDetail
        isdetail={isdetail}
        setdetail={setdetail}
        restaurant={restaurant}
        deliveryInfo={deliveryInfo}
        payment={payment}
        meals={meals}
        packages={packages}
        vendorImg={img}
        riderInfo={riderInfo}
        statusUpdate={statusUpdate}
        date={date}
      />
    </>
  );
};

export default OrderWidget;
