import React, { useState } from "react";
import DailySales from "./intervalsales/dailySales";
import MonthlySales from "./intervalsales/monthlySales";
import WeeklySales from "./intervalsales/weeklySales";

const LatestSales = () => {
  const [active1, setactive1] = useState(0);
  console.log(active1);
  const period = ["Day", "Week", "Month"];
  return (
    <div className="mt-6 bg-white rounded-md p-4">
      <div className="text-lg font-semibold mb-3">Latest Sales</div>

      <div className="flex space-x-8 items-center w-full border-b">
        {period.map((cat, idx) => {
          return (
            <div
              onClick={() => {
                setactive1(idx);
              }}
              key={idx}
              className={`px-1 cursor-pointer py-4 ${
                active1 === idx
                  ? "border-b-2 text-[#7bb43e] border-[#7bb43e]"
                  : ""
              }`}
            >
              {cat}
            </div>
          );
        })}
      </div>

      <div className="overflow-x-auto ">
        <div className="min-w-[800px grid grid-cols-9 w-full items-center pl-[3px] md:pl-[2%]  bg-gray-100 text-zinc-500 rounded-t-md   gap-2 font-medium py-4">
          <div>{"S/N"}</div>
          <div className="col-span-2">{"Orders"}</div>
          <div>Price</div>
          <div>P/S</div>
          <div>O/S</div>
          <div className="col-span-2">Date</div>
          <div>Actions</div>
        </div>
      </div>
      <DailySales active={active1} />
      <WeeklySales active={active1} />
      <MonthlySales active={active1} />
    </div>
  );
};

export default LatestSales;
