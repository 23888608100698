import React, { useState, useEffect } from "react";

import RiderWidget from "../riderwidget/riderWidget";
import { allRiders, riderStat } from "../../../Utils/api";
import { useDispatch, useSelector } from "react-redux";
import empty from "../../../assets/svg/loading.svg";
import { getRiders } from "../../../Redux/Actions/ActionCreators";
import DatatoCsv from "../../exporttoCSV/datatoCsv";
import { getNairaEquivalent } from "Utils/lib/helper";

const RidersList = () => {
  const [ismore, setismore] = useState(false);
  const [loading, setLoading] = useState(false);
  const { riders } = useSelector((state) => state.rider);
  const [data, setdata] = useState([]);
  const { token } = useSelector((state) => state.user);
  const [stat, setstat] = useState(0);
  const dispatch = useDispatch();
  const list = [];

  console.log(riders);
  useEffect(() => {
    const getStat = async () => {
      await riderStat(token)
        .then((res) => {
          const { data } = res.data;
          setstat(data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getStat();
  }, [token]);
  useEffect(() => {
    const getAllRiders = async () => {
      setLoading(true);
      await allRiders(token)
        .then((res) => {
          // console.log(res);
          const { data } = res.data;
          setLoading(false);
          dispatch(getRiders(data));
          //console.log(data)
          setdata(data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getAllRiders();
  }, [dispatch, token]);

  riders?.forEach(
    ({
      email,
      completedOrders,
      cancelledOrders,
      fullName,
      phoneNumber,
      revenue,
    }) => {
      list.push({
        fullName,
        phoneNumber,
        email,
        completedOrders,
        cancelledOrders,
        revenue: getNairaEquivalent(revenue),
      });
    }
  );

  return (
    <div className="w-full bg-white rounded-lg mb-[20px] p-4">
      <div className="flex justify-between items-center mb-3">
        <p className="font-semibold text-lg ">{`${
          riders?.length || stat
        } Riders`}</p>
        <DatatoCsv list={list} filename="Jekaeat_Rider_Data" />
      </div>

      <div className="grid grid-cols-9 w-full items-center pl-[3px] md:pl-5 text-zinc-500 rounded-t-md  gap-4 font-medium py-4 bg-gray-100 ">
        <div>S/N</div>
        <div className=" col-span-2 ">Rider</div>
        <div>Cancelled</div>
        <div>Delivered</div>
        <div>Revenue</div>
        <div>Joined</div>
        <div>Location</div>

        <div>Action</div>
      </div>
      {loading && (
        <div className="w-full h-[300px] flex justify-center items-center">
          <img src={empty} alt="" />
        </div>
      )}
      {!loading &&
        data?.map(
          (
            {
              _id,
              completedOrders,
              cancelledOrders,
              fullName,
              phoneNumber,
              revenue,
              createdAt,
              location,
            },
            idx
          ) => {
            return (
              <div key={idx}>
                <RiderWidget
                  name={fullName}
                  id={idx + 1}
                  order={cancelledOrders}
                  delivery={completedOrders}
                  phoneNumber={phoneNumber}
                  userId={_id}
                  revenue={revenue}
                  ismore={ismore}
                  setismore={setismore}
                  createdAt={createdAt}
                  location={location}
                />
              </div>
            );
          }
        )}
    </div>
  );
};

export default RidersList;
