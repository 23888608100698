import * as type from "../Actions/Types";

const initailState = {
  customers:[],
};
const Customer = (state = initailState, action) => {
  switch (action.type) {
    case type.GET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };


    default:
      return state;
  }
};

export default Customer;
