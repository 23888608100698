import React, { useState } from "react";
import Loader from "../UI/Loader";
import choosepics from "../../assets/svg/choosepics.svg";
import { IoIosClose } from "react-icons/io";
import { toast } from "react-hot-toast";
import {
  updateCustomer,
  updateImage,
  updateRider,
  updateVendor,
} from "../../Utils/api";
import { useDispatch, useSelector } from "react-redux";
import {
  updateACustomer,
  updateARider,
  updateAVendor,
} from "../../Redux/Actions/ActionCreators";

const EditUser = ({
  id,
  iseditUser,
  seteditUser,
  vname,
  userId,
  heading,
  phoneNumber,
  title,
  locAddress,
  location,
  telephone,
  profileImage,
  type,
}) => {
  const [userImage, setuserImage] = useState(profileImage);
  const { token } = useSelector((state) => state.user);
  const [isedit, setedit] = useState(false);
  const [uploadedImage, setuploadedImage] = useState(profileImage);
  const [name, setName] = useState(vname);
  const [city, setCity] = useState();
  const [phone, setPhone] = useState(phoneNumber);
  const [loading, setLoading] = useState(false);
  const [address, setaddress] = useState(locAddress);
  const dispatch = useDispatch();

  console.log(vname);
  const handleImageUpload = (e) => {
    console.log(e.target.files[0]);
    setedit(true);
    if (e.target.files[0]) {
      const file = e.target.files[0];

      const image = URL.createObjectURL(file);
      setuploadedImage(image);
      setuserImage(file);
    }
  };

  const handleEditImage = () => {
    setuploadedImage(null);
    setuserImage(null);
    setedit(true);
  };

  const handleUpdateImage = async () => {
    const formData = new FormData();
    formData.append("profileImage", userImage);

    await updateImage(userId, formData, token)
      .then((res) => {
        console.log(res);
        toast.success("profile Image updated successfully");
        setedit(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEditUser = async () => {
    if (isedit && userImage) {
      toast.error("Save user image");
      return;
    }

    setLoading(true);

    //const formData = new FormData();
    //formData.append("profileImage", userImage);
    //formData.append("businessName", name);
    //formData.append("phoneNumber", phone);

    if (type === "Vendor") {
      await updateVendor(
        userId,
        {
          //profileImage: userImage,
          businessName: name,
          phoneNumber: phone,
          restaurantLocation: { address, city },
        },
        token
      )
        .then((res) => {
          console.log(res.data);
          const { data } = res.data;
          dispatch(updateAVendor(id, data));
          setLoading(false);
          toast.success("updated successfully");
          seteditUser(!iseditUser);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (type === "Customer") {
      await updateCustomer(
        userId,
        {
          //profileImage: userImage,
          fullName: name,
          phoneNumber: phone,
          deliveryAddress: { address, contactName: name, contactPhone: phone },
        },
        token
      )
        .then((res) => {
          console.log(res);
          const { data } = res.data;
          console.log(data);
          dispatch(updateACustomer(id, data));
          setLoading(false);
          toast.success("updated successfully");
          seteditUser(!iseditUser);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (type === "Rider") {
      await updateRider(
        userId,
        {
          fullName: name,
          phoneNumber: phone,
        },
        token
      )
        .then((res) => {
          console.log(res);
          const { data } = res.data;
          console.log(data);
          dispatch(updateARider(id, data));
          setLoading(false);
          seteditUser(!iseditUser);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        seteditUser(!iseditUser);
      }}
      className={
        iseditUser
          ? "w-full h-full bg-black bg-opacity-50 z-[70] fixed inset-0 flex items-center justify-center"
          : "hidden"
      }
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="w-[350px] h-[90%] bg-white space-y-3 rounded-md pl-4 pr-3 pt-4 pb-16 overflow-hidden relative"
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
            seteditUser(!iseditUser);
          }}
          className="absolute right-3 top-2"
        >
          <IoIosClose className="text-[22px]" />
        </div>
        <div className="text-lg font-medium">{heading}</div>

        <div className="scroll w-full h-full space-y-2 items-center overflow-y-auto">
          {!uploadedImage ? (
            <div className=" border rounded-md flex w-[98%] h-[200px] flex-col space-y-[4%] justify-center items-center py-4">
              <div className="h-8 w-8">
                <img
                  className="w-[98%] h-full object-cover"
                  src={choosepics}
                  alt="choosepics"
                />
              </div>
              <div className="flex">
                <label className="label">
                  <input
                    type="file"
                    name="file"
                    accept="image/png, image/jpeg image/jpg"
                    onChange={(e) => {
                      handleImageUpload(e);
                    }}
                  />
                  <span className="text-[#7BBE4A]">Upload user image</span>
                </label>
              </div>
            </div>
          ) : (
            <img
              src={uploadedImage}
              alt="uploadedImage"
              className="object-cover h-[200px] w-[98%]"
            />
          )}

          <div className="flex w-[98%] py-4 space-x-2 justify-center items-center">
            <button onClick={handleEditImage} className="text-red-600 text-sm">
              Edit
            </button>{" "}
            {isedit && (
              <>
                <span>or</span>
                <button
                  onClick={() => {
                    handleUpdateImage();
                  }}
                  className="text-[#7bb34e] text-sm"
                >
                  Save
                </button>
              </>
            )}
          </div>
          <div className="form-group space-y-3">
            <label className="block " htmlFor="name">
              {title}
            </label>
            <input
              className="block form__input input-field border focus:border-gray-500 hover:border-gray-500 rounded-md focus:outline-none w-[98%] h-11 px-4"
              type="text"
              placeholder=""
              defaultValue={vname}
              name="name"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="form-group space-y-3">
            <label className="block " htmlFor="tel">
              {telephone}
            </label>
            <input
              className="block form__input input-field border focus:border-gray-500 hover:border-gray-500 rounded-md focus:outline-none w-[98%] h-11 px-4"
              type="number"
              placeholder=""
              defaultValue={phoneNumber}
              name="tel"
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
          </div>

          {type !== "Rider" && (
            <div className="form-group space-y-3">
              <label className="block " htmlFor="address">
                {location}
              </label>
              <input
                className="block form__input input-field border focus:border-gray-500 hover:border-gray-500 rounded-md focus:outline-none w-[98%] h-11 px-4"
                type="text"
                placeholder=""
                defaultValue={locAddress}
                name=""
                onChange={(e) => {
                  setaddress(e.target.value);
                }}
              />
            </div>
          )}

          {type !== "Rider" && (
            <div className="form-group space-y-3">
              <label className="block " htmlFor="city">
                City (For Vendor)
              </label>
              <input
                className="block form__input input-field border focus:border-gray-500 hover:border-gray-500 rounded-md focus:outline-none w-[98%] h-11 px-4"
                type="text"
                placeholder=""
                value={city}
                name=""
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
            </div>
          )}

          <button
            onClick={handleEditUser}
            className="w-[98%] py-3 bg-[#7BBE4A] rounded-lg text-white flex justify-center items-center space-x-2"
          >
            {loading ? <Loader /> : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};
export default EditUser;
