import Container from "../container/container";
import CustomerList from "./customerlist/customerList";
import CustomerCard from "../cards/customercards";

const CustomerBoard = () => {
  return (
    <Container>
      <div className="w-full h-full">
      <CustomerCard />
        <div className="flex justify-start mb-3 items-center">
          <p className="font-semibold text-lg ">Customers</p>
        </div>
       
        <CustomerList />
      </div>
    </Container>
  );
};

export default CustomerBoard;
