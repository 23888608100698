import React, { useState, useEffect } from "react";
import { bestseller } from "../../../Utils/api";
import { getNairaEquivalent } from "../../../Utils/lib/helper";
import { useSelector } from "react-redux";
import profile from "../../../assets/svg/profile.svg";
import DatatoCsv from "components/exporttoCSV/datatoCsv";
const VendorRank = () => {
  const { token } = useSelector((state) => state.user);
  const [data, setdata] = useState([]);
  const list = [];

  useEffect(() => {
    async function topSellers() {
      await bestseller(token)
        .then((res) => {
          console.log(res.data);
          const { items } = res.data.data;
          setdata(items);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    topSellers();
  }, [token]);

  data.forEach(
    ({
      businessName,
      revenue,
      completedOrders,
      email,
      fullName,
      phoneNumber,
      address,
    }) => {
      list.push({
        BusinessName: businessName,
        Revenue: revenue,
        Completed_Orders: completedOrders,
        Owner: fullName,
        Email: email,
        PhoneNumber: phoneNumber,
        RestaurantLocation: address.streetAddress,
      });
    }
  );

  return (
    <div className="bg-white rounded-lg h-[350px] space-y-3 p-4 overflow-hidden">
      <div className="flex justify-between">
        <span className=" font-semibold text-lg">Best Selling Vendors</span>
        <DatatoCsv list={list} filename="Jekaeat_Best_Selling_Vendors_Data" />
      </div>

      <div className="grid grid-cols-5 text-zinc-500 rounded-t-md  gap-5 font-medium py-2 bg-gray-100 text-sm">
        <div className=" pl-3 col-span-2 "> Vendor</div>
        <div>Ordered</div>
        <div>Delivered</div>
        <div>Revenue</div>
      </div>
      {data
        ?.slice(0, 5)
        ?.map(
          (
            {
              businessName,
              fullName,
              profileImage,
              completedOrders,
              revenue,
              totalOrders,
            },
            idx
          ) => {
            return (
              <div key={idx} className="grid grid-cols-5 py-1 gap-5  text-sm ">
                <div className="flex items-center space-x-2  col-span-2">
                  <div className="w-[24px] h-[24px] rounded-full">
                    <img
                      className="w-full h-full rounded-full"
                      src={profileImage?.url || profile}
                      alt=""
                    />
                  </div>
                  <span>{businessName}</span>
                </div>
                <div>{totalOrders}</div>
                <div>{completedOrders}</div>
                <div className="text-[#7BBE4A]">
                  {` ₦${getNairaEquivalent(revenue?.toFixed(0))}`}
                </div>
              </div>
            );
          }
        )}
    </div>
  );
};

export default VendorRank;
