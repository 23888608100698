import React, { useState } from "react";
import profile from "../../../assets/svg/profile.svg";
import { FiEdit3 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import EditUser from "../../updateuser/editUser";
import {
  deleteUser,
  openAndCloseShop,
  toggleFeatured,
} from "../../../Utils/api";
import { useDispatch, useSelector } from "react-redux";
import {
  getVendors,
  updateAVendor,
} from "../../../Redux/Actions/ActionCreators";
import DeleteModal from "../../updateuser/deleteModal";
import { toast } from "react-hot-toast";
import Loader from "components/UI/Loader";

const VendorWidget = ({
  ismore,
  setismore,
  name,
  delivery,
  order,
  phoneNumber,
  email,
  verified,
  address,
  vendorId,
  profileImage,
  id,
  shopClosed,
}) => {
  const [itemId, setitemId] = useState();
  const { token } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [iseditUser, seteditUser] = useState(false);
  const dispatch = useDispatch();
  const { vendors } = useSelector((state) => state.vendor);
  const [isdelete, setisdelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [vendorShopStatus, setVendorShopStatus] = useState(shopClosed);

  const handleOpenAndCloseShop = () => {
    setStatusLoading(true);
    openAndCloseShop(vendorId, token)
      .then((res) => {
        setStatusLoading(false);
        const { shopClosed: shopStatus } = res.data.data;
        setVendorShopStatus(shopStatus);
        if (shopStatus) {
          toast.success("Shop closed successfully");
        } else {
          toast.success("Shop opened successfully");
        }
      })
      .catch((err) => {
        setStatusLoading(false);

        console.log(err);
      });
  };
  const toggleVendor = async () => {
    setLoading(true);
    const payload = {
      featured: verified ? false : true,
    };
    await toggleFeatured(vendorId, payload, token)
      .then((res) => {
        console.log(res);
        const { data } = res.data;
        setLoading(false);
        toast.success(`vendor ${verified ? "deactivated" : "activated"}`);
        dispatch(updateAVendor(id - 1, data));
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const removeUser = () => {
    setisdelete(!isdelete);
  };
  return (
    <>
      <div className="grid grid-cols-7 pl-[3px] md:pl-[5%] py-2 border-b gap-5  items-center text-sm ">
        <Link
          to={`/vendor/${vendorId}`}
          className="grid grid-cols-6 gap-[60px] items-center col-span-2"
        >
          <div className="w-[50px] h-[50px] rounded-full">
            <img
              className="w-full h-full rounded-full"
              src={profileImage || profile}
              alt=""
            />
          </div>
          <div className="flex flex-col space-y-1 justify-start col-span-4">
            <span className="font-medium w-[150px] text-ellipsis whitespace-nowrap overflow-hidden">
              {name}
            </span>
            <span className="w-[120px] min-[850px]:w-[160px] lg:w-[120px] xl:w-[200px] text-gray-500 text-[13px] text-ellipsis whitespace-nowrap overflow-hidden ">
              {address}
            </span>

            <span className="text-gray-500 text-[13px]">{phoneNumber}</span>
          </div>
        </Link>
        <div>
          <button
            onClick={handleOpenAndCloseShop}
            className={`${
              vendorShopStatus ? "bg-red-500 " : "bg-primary-100"
            }  rounded-md px-2 py-1 text-white text-center`}
          >
            {statusLoading ? (
              <Loader />
            ) : (
              <div>
                {vendorShopStatus ? <span>Offline</span> : <span>Online</span>}
              </div>
            )}
          </button>
        </div>
        <div>{order}</div>
        <div>{delivery}</div>
        <div className="flex space-x-1 text-[20px] text-gray-300 cursor-pointer">
          <div
            onClick={() => {
              seteditUser(!iseditUser);
            }}
          >
            <FiEdit3 />
          </div>
          <div
            onClick={() => {
              removeUser();
            }}
          >
            <RiDeleteBin6Line />
          </div>

          <div
            onClick={() => {
              setitemId(id);
              setismore(!ismore);
            }}
            className="relative"
          >
            <BiDotsHorizontalRounded />
            {ismore && itemId === id && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="right-[-50px]  absolute min-w-max"
              >
                {/**attach user id */}
                <span
                  onClick={() => {
                    navigate(`/vendor/${vendorId}`, {
                      state: {
                        name,
                      },
                    });
                  }}
                  className="text-zinc-700 relative bg-white p-2 rounded-md z-[300] min-w-max text-[13px] shadow-lg "
                >
                  view more
                </span>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setitemId("");
                    setismore(!ismore);
                  }}
                  className="w-full h-full fixed inset-0 z-[40] bg-none"
                ></div>
              </span>
            )}
          </div>
        </div>
        {loading && (
          <div
            className={`w-fit  px-[34px]  py-[6px] rounded-md h-fit flex items-center justify-center ${
              verified
                ? "bg-red-500 bg-opacity-50"
                : "bg-[#7bb43e] bg-opacity-50"
            }`}
          >
            <span
              className={`rounded-full h-4 w-4 animate-spin border-l border-t border-r ${
                verified ? "border-red-500" : "border-[#7bb43e]"
              } `}
            ></span>
          </div>
        )}
        {!loading && (
          <div
            onClick={toggleVendor}
            className={`flex w-fit  rounded-md  ${
              verified
                ? "bg-[#7bb43e] bg-opacity-50"
                : "bg-red-500 bg-opacity-50"
            } `}
          >
            {verified ? (
              <button className="text-white px-2 py-1">Deactivate</button>
            ) : (
              <button className="text-red-500 px-4 py-1">Activate</button>
            )}
          </div>
        )}
      </div>

      <EditUser
        id={id - 1}
        vname={name}
        heading={"Edit Vendor"}
        title={"Vendor Title"}
        telephone={"Vendor Phone Number"}
        psw={"Vendor Password"}
        location={"Vendor Address"}
        usercity={"Vendor City"}
        phoneNumber={phoneNumber}
        userId={vendorId}
        type={"Vendor"}
        iseditUser={iseditUser}
        profileImage={profileImage}
        seteditUser={seteditUser}
      />

      <DeleteModal
        isdelete={isdelete}
        setisdelete={setisdelete}
        token={token}
        id={vendorId}
        data={vendors}
        deleteFn={deleteUser}
        dispatch={dispatch}
        getData={getVendors}
      />
    </>
  );
};

export default VendorWidget;
