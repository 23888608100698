import * as type from "../Actions/Types";

const initailState = {
  vendors:[],
};
const Vendor = (state = initailState, action) => {
  switch (action.type) {
    case type.GET_VENDORS:
      return {
        ...state,
        vendors: action.payload,
      };


    default:
      return state;
  }
};

export default Vendor;
