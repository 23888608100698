import React, { useEffect, useState } from "react";
import Container from "../container/container";
import {
  deleteNotification,
  getActiveNotifications,
  pauseNotification,
} from "../../Utils/api";
import { useSelector } from "react-redux";
import Loader from "../UI/Loader";
import { FaRegPauseCircle } from "react-icons/fa";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { LuUsers } from "react-icons/lu";
import { AiOutlinePlus } from "react-icons/ai";
// import { CgNametag } from "react-icons/cg";
import empty from "assets/png/emptyorder.png";

import moment from "moment";
import timeFormatter from "Utils/lib/stringtoDate";
import CreateBroadcast from "components/UI/CreateBroadcast";
import { errMsg } from "Utils/lib/helper";
import toast from "react-hot-toast";

const Notification = () => {
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "a few seconds",
      ss: "%d seconds",
      m: "a minute",
      mm: "%d minutes",
      h: "an hour",
      hh: "%d hours",
      d: "a day",
      dd: "%d days",
      w: "a week",
      ww: "%d weeks",
      M: "a month",
      MM: "%d months",
      y: "a year",
      yy: "%d years",
    },
  });
  const daysObject = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  };
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newBC, setNewBC] = useState(false);
  const { token } = useSelector((state) => state.user);

  function getDay(dayNo) {
    return daysObject[dayNo];
  }
  function handleClose() {
    setNewBC(false);
  }
  const getNotification = async () => {
    setLoading(true);
    await getActiveNotifications(token)
      .then((res) => {
        const { data } = res.data;
        console.log(data);
        setLoading(false);
        setdata(data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const handlePauseNotification = async (id) => {
    setLoading(true);
    await pauseNotification(id, token)
      .then((res) => {
        setLoading(false);
        toast.success("Notification stopped successfully");
        getNotification();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        errMsg(err);
      });
  };
  const handleDeleteNotification = async (id) => {
    setLoading(true);
    await deleteNotification(id, token)
      .then((res) => {
        setLoading(false);
        toast.success("Notification deleted successfully");
        getNotification();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        errMsg(err);
      });
  };
  useEffect(() => {
    getNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {newBC && (
        <CreateBroadcast
          handleClose={handleClose}
          getNotification={getNotification}
        />
      )}
      <div className="w-full space-y-4 bg-white h-full overflow-y-auto pb-10">
        <div className="w-full rounded-md bg-white px-2 pt-4 text-zinc-700">
          <div className="flex items-center space-x-2 w-full">
            <span className="font-semibold text-lg">Notifications</span>
          </div>
        </div>

        <div className="flex justify-end px-4">
          <button
            onClick={() => {
              setNewBC(true);
            }}
            className="flex items-center space-x-1 text-xs bg-primary-100 text-white  p-2 rounded-md"
          >
            <AiOutlinePlus />
            <span>Schedule Broadcast</span>
          </button>
        </div>

        <div className="w-full px-2 space-y-2 min-w-[800px]  overflow-x-auto">
          {!loading ? (
            <div>
              {data.length > 0 ? (
                <div className="space-y-4">
                  {data.map((item) => (
                    <div
                      key={item._id}
                      className="p-2  rounded-md bg-gray-200/80"
                    >
                      <div className="flex justify-between mb-2">
                        <div>
                          {item.status === "active" ? (
                            <span className="bg-primary-100 text-white rounded-md py-1 px-2 capitalize text-[10px]">
                              {item.status}
                            </span>
                          ) : (
                            <span className="bg-red-500 text-white rounded-md py-1 px-2 capitalize text-[10px]">
                              {item.status}
                            </span>
                          )}
                        </div>
                        <div className="flex items-center space-x-1">
                          <LuUsers /> <span>{item.userType}</span>
                        </div>
                        <div className="flex space-x-2">
                          <button
                            onClick={() => {
                              handlePauseNotification(item._id);
                            }}
                            className="flex items-center space-x-1 text-xs bg-blue-300 text-white p-1 rounded-md"
                          >
                            <FaRegPauseCircle />
                            <span>Stop</span>
                          </button>
                          <button
                            onClick={() => {
                              handleDeleteNotification(item._id);
                            }}
                            className="flex items-center space-x-1 text-xs bg-red-400 text-white p-1 rounded-md"
                          >
                            <MdOutlineDeleteOutline />
                            <span>Delete</span>
                          </button>
                        </div>
                      </div>
                      <div className="flex justify-between ">
                        <div className="flex space-x-1">
                          <h1 className="font-medium"> Name: </h1>
                          <span> {item.name}</span>
                        </div>

                        <div className="text-gray-400">
                          {moment(item.createdAt).fromNow()}
                        </div>
                      </div>

                      <div className="space-y-2 my-3">
                        <div className="flex space-x-1">
                          <h1 className="font-medium"> Title: </h1>
                          <span>{item.notification.title}</span>
                        </div>
                        <div className="flex space-x-1">
                          <h1 className="font-medium"> Body: </h1>
                          <span>{item.notification.body}</span>
                        </div>

                        <div className="flex space-x-2">
                          <div className="flex space-x-1">
                            <h1 className="font-medium"> Time: </h1>
                            <span>{timeFormatter(item.time)}</span>
                          </div>
                        </div>

                        <div className="flex space-x-2">
                          <h1 className="font-medium">Days:</h1>
                          <div className="flex space-x-2">
                            <span>Every</span>{" "}
                            {item.days.map((day) => (
                              <div className="ml-1 flex space-x-1 items-center">
                                <div className="w-1 h-1 bg-primary-100"></div>
                                <p>{getDay(day)}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="w-full h-[300px] flex justify-center items-center">
                  <span className="w-[200px] h-[200px]">
                    <img className="w-full h-full" src={empty} alt="" />
                  </span>
                </div>
              )}
            </div>
          ) : (
            <div className="flex justify-center items-center py-[100px]">
              <Loader />
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Notification;
