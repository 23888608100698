import React, { useEffect, useState } from "react";
import Container from "../container/container";
import Loader from "components/UI/Loader";
import Pagination from "components/cards/pagination";
import { allPayments, paystackBalance, reprocessPayout } from "../../Utils/api";
import { getNairaEquivalent } from "../../Utils/lib/helper";
import { useSelector } from "react-redux";
import profile from "../../assets/svg/profile.svg";
import toast from "react-hot-toast";
import moment from "moment";
import loadings from "assets/svg/loading.svg";

const Payment = () => {
  const [data, setdata] = useState(null);
  const [paging, setPaging] = useState({
    totalItems: 0,
    currentPage: 1,
  });
  const [affectedRowId, setaffectedRowId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [balance, setbalance] = useState(null);

  const fetchAllPAyments = async (token, currentPage) => {
    setLoadingData(true);
    await allPayments(token, currentPage)
      .then((res) => {
        console.log(res);
        const { items, paging } = res.data.data;
        setdata(items);
        setPaging(paging);
        setLoadingData(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingData(false);
      });

    await paystackBalance(token)
      .then((res) => {
        console.log(res.data.data);
        const { data } = res.data;
        setbalance(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  async function handlePayout(id) {
    setLoading(true);
    setLoading(true);
    await reprocessPayout(id, token)
      .then((res) => {
        setLoading(false);
        toast.success(res?.data?.message);
        setaffectedRowId(null);
        fetchAllPAyments();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.message);
        setaffectedRowId(null);
      });
  }

  useEffect(() => {
    fetchAllPAyments(token, paging.currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <div className="w-full  pb-10 h-full">
        <div className="bg-white grid place-content-center">
          {loadingData ? (
            <img src={loadings} alt="" />
          ) : (
            <div>
              <div className="w-full rounded-t-md  px-2 py-4 text-zinc-700">
                <div className="flex items-center space-x-2 w-full">
                  <span className="font-semibold text-lg">
                    Paystack Balance:
                  </span>
                  {balance === null ? (
                    <span className=" ml-2 font-semibold text-lg">
                      Loading...
                    </span>
                  ) : (
                    <span className="font-semibold text-lg">{`${
                      balance?.currency
                    }  ${(balance?.balance / 100).toLocaleString()}`}</span>
                  )}
                </div>
              </div>

              <div className="w-full  px-2  space-y-2 overflow-x-auto">
                <div className="min-w-[800px] grid grid-cols-12 text-zinc-500 rounded-t-md  gap-5 font-medium py-2 bg-gray-100 text-sm">
                  <div className=" pl-3 col-span-3 "> Name</div>
                  <div className="col-span-1"> Amount</div>
                  <div className="col-span-2">Initiated Date</div>
                  <div className="col-span-1">Period</div>
                  <div className="col-span-2">Processed Date</div>
                  <div className="col-span-1">Status</div>
                  <div className="col-span-2">Action</div>
                </div>

                <div>
                  {data?.map(
                    (
                      {
                        _id,
                        user,
                        createdAt,
                        processedAt,
                        status,
                        processPeriod,
                        amount,
                      },
                      idx
                    ) => {
                      return (
                        <div
                          key={idx}
                          className=" min-w-[800px] grid grid-cols-12 text-zinc-700 rounded-t-md  gap-5  py-2 "
                        >
                          <div className="grid gap-1 grid-cols-6 items-center col-span-3">
                            <div className="rounded-full w-[30px] h-[30px]">
                              <img
                                className="w-full h-full rounded-full col-span-5"
                                src={user?.profileImage?.url || profile}
                                alt=""
                              />
                            </div>
                            <div className="text-ellipsis whitespace-nowrap w-[150px] overflow-hidden">
                              {user?.fullName}
                            </div>
                          </div>
                          <div className="col-span-1">
                            ₦{getNairaEquivalent(amount)}
                          </div>
                          <div className="col-span-2">
                            {moment(createdAt)
                              .subtract(1, "hours")
                              .format("lll")}
                          </div>
                          <div className="col-span-1">{processPeriod}</div>
                          <div className="col-span-2">
                            {processedAt
                              ? moment(processedAt)
                                  .subtract(1, "hours")
                                  .format("lll")
                              : "Not processed yet"}
                          </div>
                          <div className="font-medium col-span-1">{status}</div>
                          <div className="font-medium col-span2">
                            {!processedAt && (
                              <button
                                onClick={() => {
                                  handlePayout(_id);
                                  setaffectedRowId(idx);
                                }}
                                className=" min-w-[100px] w-full px-4 font-medium text-white bg-[#7bb43e] py-2 rounded-md"
                              >
                                {loading && affectedRowId === idx ? (
                                  <Loader />
                                ) : (
                                  "Process"
                                )}
                              </button>
                            )}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <Pagination
          totalItems={paging.totalItems}
          currentPage={paging.currentPage}
          token={token}
          fetchAllPAyments={fetchAllPAyments}
        />
      </div>
    </Container>
  );
};

export default Payment;
