import React, { useEffect, useState } from "react";
import Container from "../container/container";
import { getFeedback } from "../../Utils/api";
import { useSelector } from "react-redux";
import Loader from "../UI/Loader";

const Payment = () => {
  const [data, setdata] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.user);

  useEffect(() => {
    const GetFeedback = async () => {
      setLoading(true);
      await getFeedback(token)
        .then((res) => {
          console.log(res);
          const { data } = res.data;
          setLoading(false);
          setdata(data.reverse());
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    };
    GetFeedback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function formatDate(datestring) {
    const date = new Date(datestring);

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return date.toLocaleString("en-US", options);
  }

  return (
    <Container>
      <div className="w-full space-y-4 bg-white h-full">
        <div className="w-full rounded-md bg-white px-2 py-4 text-zinc-700">
          <div className="flex items-center space-x-2 w-full">
            <span className="font-semibold text-lg">Feedbacks</span>
          </div>
        </div>

        <div className="w-full px-2 space-y-2 min-w-[800px]  overflow-x-auto">
          <div className=" grid grid-cols-12 text-zinc-500 rounded-t-md  gap-5 font-medium py-2 bg-gray-100 text-sm">
            <div className="col-span-2">Date</div>
            <div className="col-span-2 "> Name</div>
            <div className="w-full col-span-8">Feedback</div>
          </div>

          {!loading ? (
            <div className="min-w-[800px] overflow-x-auto">
              {data?.map(({ user, createdAt, message }, idx) => {
                return (
                  <div
                    key={idx}
                    className="border-b grid grid-cols-12 text-zinc-700 rounded-t-md  gap-5  py-2 "
                  >
                    <div className="col-span-2">{formatDate(createdAt)}</div>
                    <div className=" col-span-2 text-ellipsis whitespace-nowrap w-[150px] overflow-hidden">
                      {user?.fullName}
                    </div>
                    <div className="flex flex-wrap w-full col-span-8">
                      {message}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex justify-center items-center py-[100px]">
              <Loader />
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Payment;
