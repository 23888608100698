import React, { useState } from "react";
import Loader from "../UI/Loader";
import dropdown from "../../assets/svg/dropdown.svg";
import choosepics from "../../assets/svg/choosepics.svg";
import { IoIosClose } from "react-icons/io";
import { useSelector } from "react-redux";
import { addUser } from "../../Utils/api";
import { toast } from "react-hot-toast";
const AddUser = ({ isadduser, setadduser }) => {
  const [userImage, setuserImage] = useState("");
  const [uploadedImage, setuploadedImage] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [businessName, setbusinessName] = useState("");

  const [user, setuser] = useState("");
  const [showuser, setShowuser] = useState(false);
  const { token } = useSelector((state) => state.user);
  const userCategory = [
    { user: "select an option..." },
    { user: "Rider" },
    { user: "Vendor" },
  ];
  const handleAddUser = async () => {
    const validateData = {
      profileImage: userImage,
      fullName: name,
      phoneNumber: phone,
      password: password,
      type: user === "select an option..." ? "" : user,
      // businessName: businessName,
      email: email,
    };

    for (let i in validateData) {
      if (validateData[i] === "") {
        toast.error(`${i} is required`);
        return;
      }
    }
    const payload = {
      image: userImage,
      fullName: name,
      phoneNumber: phone,
      password: password,
      type: user,
      businessName: businessName,
      email: email,
    };
    const arider = {
      profileImage: userImage,
      fullName: name,
      phoneNumber: phone,
      password: password,
      type: user,

      email: email,
    };
    const avendor = {
      profileImage: userImage,
      fullName: name,
      phoneNumber: phone,
      password: password,
      type: user,
      businessName: businessName,
      email: email,
    };
    console.log(payload);
    setLoading(true);
    await addUser(user === "Rider" ? arider : avendor, token)
      .then((res) => {
        console.log(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.error.message);
      });
  };

  const handleImageUpload = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files[0]) {
      const file = e.target.files[0];

      const image = URL.createObjectURL(file);
      setuploadedImage(image);
      setuserImage(file);
    }
  };

  const handleEditImage = () => {
    setuploadedImage(null);
    setuserImage(null);
  };
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setadduser(!isadduser);
      }}
      className={
        isadduser
          ? "w-full h-full bg-black bg-opacity-50 z-[70] fixed inset-0 flex items-center justify-center"
          : "hidden"
      }
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="w-[350px] h-[90%] bg-white space-y-3 rounded-md pl-4 pr-3 pt-4 pb-16 overflow-hidden relative"
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
            setadduser(!isadduser);
          }}
          className="absolute right-3 top-2"
        >
          <IoIosClose className="text-[22px]" />
        </div>
        <div className="text-lg font-medium">Add User</div>

        <div className="scroll w-full h-full space-y-2 items-center overflow-y-auto">
          {!uploadedImage ? (
            <div className=" border rounded-md flex w-[98%]  h-[200px] flex-col space-y-[4%] justify-center items-center py-4">
              <div className="h-8 w-8">
                <img
                  className="w-[98%] h-full object-cover"
                  src={choosepics}
                  alt="choosepics"
                />
              </div>
              <div className="flex">
                <label className="label">
                  <input
                    type="file"
                    name="file"
                    accept="image/png, image/jpeg image/jpg"
                    onChange={(e) => {
                      handleImageUpload(e);
                    }}
                  />
                  <span className="text-[#7BBE4A]">Upload user image</span>
                </label>
              </div>
            </div>
          ) : (
            <img
              src={uploadedImage}
              alt="uploadedImage"
              className="object-cover h-[200px] w-[98%]"
            />
          )}

          <div className="flex w-[98%] py-4 space-x-2 justify-center items-center">
            <button
              onClick={() => {
                handleEditImage();
              }}
              className="text-[#7bb34e] text-sm"
            >
              Edit
            </button>
          </div>
          <div className="form-group space-y-3">
            <label className="block " htmlFor="name">
              Username
            </label>
            <input
              className="block form__input input-field border focus:border-gray-500 hover:border-gray-500 rounded-md focus:outline-none w-[98%] h-11 px-4"
              type="text"
              placeholder=""
              value={name}
              name="name"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="form-group space-y-3">
            <label className="block " htmlFor="email">
              Email
            </label>
            <input
              className="block form__input input-field border focus:border-gray-500 hover:border-gray-500 rounded-md focus:outline-none w-[98%] h-11 px-4"
              type="email"
              placeholder=""
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="form-group space-y-3">
            <label className="block " htmlFor="number">
              Phone Number
            </label>
            <input
              className="block form__input input-field border focus:border-gray-500 hover:border-gray-500 rounded-md focus:outline-none w-[98%] h-11 px-4"
              type="number"
              placeholder=""
              name="tel"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
          </div>
          <div className="form-group space-y-3">
            <label className="block " htmlFor="password">
              Password
            </label>
            <input
              className="block form__input input-field border focus:border-gray-500 hover:border-gray-500 rounded-md focus:outline-none w-[98%] h-11 px-4"
              type="password"
              placeholder=""
              name="name"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>

          <div className="space-y-[3%]">
            <p>User type</p>

            <div className=" relative">
              <div
                onClick={() => {
                  setShowuser(!showuser);
                }}
                id={showuser ? "show-cat" : ""}
                className="rounded-md w-[98%] px-3 py-1 bg-white border flex justify-start items-center h-11"
              >
                <span className="text-sm">{user || "select an option..."}</span>
              </div>
              <span
                onClick={() => {
                  setShowuser(!showuser);
                }}
                className="absolute h-3 w-4 right-3 top-3.5"
              >
                <img src={dropdown} alt="dropdown" />
              </span>
              {showuser && (
                <div className="absolute inset-x-0 h-fit space-y-[3%] border-b border-l border-r w-[98%] z-30 py-3 bg-white rounded-b-md ">
                  {userCategory.map((item, index) => {
                    return (
                      <div
                        className="w-[98%] flex justify-start items-center transform ease-in-out transition-all duration-200"
                        key={index}
                      >
                        <span
                          onClick={() => {
                            setuser(item.user);

                            setShowuser(!showuser);
                          }}
                          className="hover:bg-gray-100 px-3 py-2 w-[98%]"
                        >
                          {item.user}
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>

          {user === "Vendor" && (
            <div className="form-group space-y-3">
              <label className="block " htmlFor="name">
                Restaurant Name
              </label>
              <input
                className="block form__input input-field border focus:border-gray-500 hover:border-gray-500 rounded-md focus:outline-none w-[98%] h-11 px-4"
                type="text"
                placeholder=""
                value={businessName}
                name="name"
                onChange={(e) => {
                  setbusinessName(e.target.value);
                }}
              />
            </div>
          )}

          <button
            onClick={handleAddUser}
            className="w-[98%] py-3 bg-[#7BBE4A] rounded-lg text-white flex justify-center items-center space-x-2"
          >
            {loading ? <Loader /> : "Add User"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
