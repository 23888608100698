import React from "react";
import OrderWidget from "./orderWidget";
import Loader from "../UI/Loader";
import loadings from "../../assets/svg/loading.svg";
import empty from "../../assets/png/emptyorder.png";
import DatatoCsv from "components/exporttoCSV/datatoCsv";
import moment from "moment";
import { calculateTotalPrice } from "paystack-transaction-charges-to-cus";
import { getNairaEquivalent } from "../../Utils/lib/helper";

const Orders = ({
  id,
  data,
  nextPageLoad,
  totalamount,
  totalTransactions,
  title,
  isEmpty,
  page,
  setstartDate,
  setendDate,
  setPage,
  loading,
}) => {
  const list = [];

  function getStatusTime(statusUpdatesArray, status) {
    const result = statusUpdatesArray.find(
      (item) => item.status.toLowerCase() === status.toLowerCase()
    );
    return result
      ? moment(result.updatedAt).subtract(1, "hours").format("HH:mm")
      : "";
  }

  data.forEach(
    ({
      packages,
      deliveryInfo,
      payment,
      vendor,
      status,
      createdAt,
      total,
      rider,
      statusUpdate,
      serviceFee,
    }) => {
      const paystackCharges =
        Math.ceil(calculateTotalPrice(total + deliveryInfo.fee + serviceFee)) -
        (total + deliveryInfo.fee + serviceFee);
      list.push({
        Date: moment(createdAt).format("L"),
        Day: moment(createdAt).format("dddd"),
        Time_Created: moment(createdAt).subtract(1, "hours").format("HH:mm"), //remove one hour that moment add to the formatted time
        Time_Accepted: getStatusTime(statusUpdate, "accepted"),
        Time_Dispatched: getStatusTime(statusUpdate, "dispatching"),
        Time_Delivered: getStatusTime(statusUpdate, "delivered"),
        Time_Cancelled: getStatusTime(statusUpdate, "cancelled"),
        DeliveryName: deliveryInfo.contactName,
        DeliveryAddress: deliveryInfo?.address?.streetAddress,
        DeliveryContact: deliveryInfo.contactPhone1,
        DeliveryFee: getNairaEquivalent(deliveryInfo.fee),
        RiderFee: getNairaEquivalent(deliveryInfo.ridersFee),
        Rider: rider?.fullName,
        PaymentStatus: payment.status,
        Vendor: vendor?.businessName,
        Amount: getNairaEquivalent(total),
        Paystack_Charges: getNairaEquivalent(paystackCharges),
        serviceFee: getNairaEquivalent(serviceFee),
        Order_1: packages[0].items
          ?.map((item) => item?.product?.name)
          .toString(),
        Order_2: packages[1]
          ? packages[1].items?.map((item) => item?.product?.name).toString()
          : "",
        Order_3: packages[2]
          ? packages[2].items?.map((item) => item?.product?.name).toString()
          : "",
        Order_4: packages[3]
          ? packages[3].items?.map((item) => item?.product?.name).toString()
          : "",
        Order_5: packages[4]
          ? packages[4].items?.map((item) => item?.product?.name).toString()
          : "",
      });
    }
  );

  console.log(data);

  return (
    <div className="space-y-3">
      <div className="flex space-x-3 text-[13px] items-center text-gray-500">
        <div>Filter By:</div>
        <div className="bg-white border rounded-md p-1 flex justify-center items-center">
          <span>Start Date:</span>
          <input
            type="date"
            onChange={(e) => {
              setstartDate(e.target.value);
            }}
          />
        </div>

        <div className="bg-white border rounded-md p-1 flex justify-center items-center">
          <span>End Date:</span>
          <input
            type="date"
            onChange={(e) => {
              setendDate(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="flex justify-between">
        <div className="flex items-center space-x-6">
          <span>{`Number of transcations: ${totalTransactions}`}</span>
          <span>{`Total amount: ₦${Math.floor(
            totalamount
          )?.toLocaleString()}`}</span>
        </div>

        <div>
          <DatatoCsv list={list} filename="Jekaeat_Transaction_Data" />
        </div>
      </div>

      <div className="grid grid-cols-9 w-full items-center pl-[3px] md:pl-[2%]  bg-gray-100 text-zinc-500 rounded-t-md   gap-2 font-medium py-4">
        <div>{id}</div>
        <div className="col-span-2">{title}</div>
        <div>Price</div>
        <div>P/S</div>
        <div>O/S</div>
        <div className="col-span-2">Date</div>
        <div>Actions</div>
      </div>
      {loading && (
        <div className="w-full h-[300px] flex justify-center items-center">
          <img src={loadings} alt="" />
        </div>
      )}
      {!loading && data?.length === 0 && (
        <div className="w-full h-[300px] flex justify-center items-center">
          <span className="w-[200px] h-[200px]">
            <img className="w-full h-full" src={empty} alt="" />
          </span>
        </div>
      )}
      {!loading &&
        data?.length !== 0 &&
        data?.map(
          (
            {
              packages,
              meals,
              deliveryInfo,
              payment,
              vendor,
              status,
              createdAt,
              total,
              rider,
              statusUpdate,
            },
            idx
          ) => {
            console.log(packages);

            return (
              <div key={idx}>
                <OrderWidget
                  id={idx + 1}
                  name={packages[0].items
                    ?.map((item) => item?.product?.name)
                    .toString()}
                  // name={"Ad"}
                  img={vendor?.profileImage?.url}
                  price={getNairaEquivalent(total)}
                  restaurant={vendor?.businessName}
                  date={moment(createdAt).format("llll")}
                  createdAt={createdAt}
                  deliveryInfo={deliveryInfo}
                  meals={meals}
                  packages={packages}
                  orderStatus={status}
                  payment={payment}
                  riderInfo={rider}
                  statusUpdate={statusUpdate}
                />
              </div>
            );
          }
        )}

      {!loading && (
        <div className="w-full flex items-center pt-4 justify-center">
          {!isEmpty && (
            <button
              onClick={() => {
                setPage(page + 1);
              }}
              className="text-white bg-[#7bb43e] w-[200px] flex items-center justify-center rounded-xl py-2"
            >
              {nextPageLoad ? <Loader /> : <span>show more</span>}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Orders;
