import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { customerStat } from "../../Utils/api";
const CustomerCards = () => {
  const { token } = useSelector((state) => state.user);

  const [data, setdata] = useState();

  //console.log(token)

  useEffect(() => {
    async function getStats() {
      await customerStat(token)
        .then((res) => {
          const { data } = res.data;
          console.log(data);
          setdata(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full ">
      <div className="w-full h-full flex gap-6">
        <div className="w-1/3 h-[150px] flex items-center justify-center space-x-3  rounded-lg bg-[#fff] px-3 py-4">
          <div className="flex flex-col justify-center items-center space-y-3">
            <div className="font-medium text-2xl">{data?.totalUsers}</div>
            <div>Total Number of Customers</div>
            <div className="text-[12px] text-gray-400">{`${data?.percentageChange.toFixed(
              0
            )}% increase from last month`}</div>
          </div>
        </div>

        <div className="w-1/3 h-[150px] flex items-center justify-center space-x-3 rounded-lg bg-[#fff] px-3 py-4">
          <div className="flex flex-col justify-center items-center space-y-3">
            <div className="font-medium text-2xl">{data?.lastMonthUsers}</div>
            <div>Total Number of Customers Last Month</div>
            <div className="text-[12px] text-gray-400">
              Last Month New Customers
            </div>
          </div>
        </div>
        <div className="w-1/3 h-[150px] flex items-center justify-center space-x-3 rounded-lg bg-[#fff] px-3 py-4">
          <div className="flex flex-col justify-center items-center space-y-3">
            <div className="font-medium text-2xl">
              {data?.currentMonthUsers}
            </div>
            <div>Total Number of New Customers</div>
            <div className="text-[12px] text-gray-400">
              New Customers added this month
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerCards;
