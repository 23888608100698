export const dateOfMonth = (dateString) => {
  const date = new Date(dateString);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();
  const day = date.getUTCDate();

  return `${month}, ${day} ${year}`;
};

export const daily = (dateString) => {
  const date = new Date(dateString);
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfWeek = daysOfWeek[date.getUTCDay()];
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();
  const day = date.getUTCDate();

  return `${dayOfWeek}, ${month} ${day} ${year}`;
};

export const dailyHours = (utcTimestamp) => {
  const date = new Date(utcTimestamp);

  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");

  const hourformat = hours % 12 || 12;
  const period = hours >= 12 ? "PM" : "AM";

  return `${hourformat}:${minutes}:${seconds} ${period}`;
};

export default function timeFormatter(timeStr) {
  // Split the time string into hours and minutes
  const [hours, minutes] = timeStr.split(":").map(Number);

  // Determine whether it's AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12; // Handle midnight (0) as 12

  // Ensure single-digit hours and minutes are padded with a leading zero
  const formattedHoursStr = String(formattedHours).padStart(2, "0");
  const formattedMinutesStr = String(minutes).padStart(2, "0");

  // Construct the formatted time string
  const formattedTime = `${formattedHoursStr}:${formattedMinutesStr} ${period}`;

  return formattedTime;
}
