export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const FETCH_USER_START = "FETCH_USER_START";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_START = "LOGIN_START";

export const LOGOUT = "LOGOUT";

export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS";
export const GET_JOB_SUCCESS = "GET_JOB_SUCCESS";

export const GET_VENDORS = 'GET_VENDORS';
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_RIDERS = "GET_RIDERS";
export const GET_MAINMEAL = "GET_MAINMEAL";
export const GET_SIDEMEAL = "GET_SIDEMEAL";
export const GET_MEALPACK = "GET_MEALPACK";
export const GET_OTHERS = "GET_OTHERS";
