import React, { useState } from "react";
import { FiEdit3 } from "react-icons/fi";
import profile from "../../../assets/svg/profile.svg";
import { RiDeleteBin6Line } from "react-icons/ri";
import EditMeal from "../updatemeal/editMeal";
import { useDispatch, useSelector } from "react-redux";
import { deleteMeal } from "../../../Utils/api";
import DeleteMealModal from "../deletemeal/deleteMealModal";

const MealWidget = ({ id, name, unitPrice, image, cat, type, mealId }) => {
  const meals = useSelector((state) => state.meal);
  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isdelete, setisdelete] = useState(false);
  const [iseditMeal, seteditMeal] = useState(false);

  const handleDeleteMeal = () => {
    setisdelete(!isdelete);
  };

  return (
    <div>
      <div className="grid grid-cols-5 pl-[3px] md:pl-[5%] py-2 border-b gap-5  items-center text-sm ">
        <div>{id}</div>
        <div className="grid grid-cols-6 gap-[50px] items-center col-span-2">
          <div className="rounded-full w-[40px] h-[40px]">
            <img
              className="w-full h-full rounded-full"
              src={image || profile}
              alt=""
            />
          </div>
          <div className="font-medium col-span-4 w-[80px] min-[850px]:w-[160px] lg:w-[120px] xl:w-[200px] text-ellipsis whitespace-nowrap overflow-hidden">
            {name}
          </div>
        </div>
        <div>{`₦${(unitPrice || 0)?.toLocaleString()}`}</div>
        <div className="flex space-x-1 text-[20px] text-gray-300 cursor-pointer">
          <div
            onClick={() => {
              seteditMeal(!iseditMeal);
            }}
          >
            <FiEdit3 />
          </div>
          <div
            onClick={() => {
              handleDeleteMeal();
            }}
          >
            <RiDeleteBin6Line />
          </div>
        </div>
      </div>

      <EditMeal
        fname={name}
        image={image}
        cat={cat}
        id={id - 1}
        mealId={mealId}
        types={type}
        unitPrice={unitPrice}
        iseditMeal={iseditMeal}
        seteditMeal={seteditMeal}
      />

      <DeleteMealModal
        isdelete={isdelete}
        setisdelete={setisdelete}
        data={meals}
        id={mealId}
        token={token}
        mealtype={type || cat}
        dispatch={dispatch}
        deleteFn={deleteMeal}
      />
    </div>
  );
};

export default MealWidget;
