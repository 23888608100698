import React, { useState } from "react";
import profile from "../../../assets/png/customerpic.png";
import { FiEdit3 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import EditUser from "../../updateuser/editUser";
import { useSelector, useDispatch } from "react-redux";
import { deleteUser } from "../../../Utils/api";
import { getCustomers } from "../../../Redux/Actions/ActionCreators";
import DeleteModal from "../../updateuser/deleteModal";
import moment from "moment";

const CustomerWidget = ({
  name,
  order,
  address,
  userId,
  phoneNumber,
  id,
  createdAt,
}) => {
  const [iseditUser, seteditUser] = useState(false);
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const { customers } = useSelector((state) => state.customer);
  const [isdelete, setisdelete] = useState(false);

  const removeUser = () => {
    setisdelete(!isdelete);
  };
  console.log(createdAt);
  return (
    <div>
      <div className="grid grid-cols-6 pl-[3px] md:pl-[5%] py-2 border-b gap-5  items-center text-sm ">
        <div>{id}</div>
        <div className="grid grid-cols-6 gap-[60px] items-center col-span-2">
          <div className="w-[50px] h-[50px] rounded-full">
            <img className="w-full h-full rounded-full" src={profile} alt="" />
          </div>
          <div className="flex flex-col space-y-1 justify-start col-span-4">
            <span className="font-medium w-[150px] text-ellipsis whitespace-nowrap overflow-hidden">
              {name}
            </span>
            <span className="w-[120px] min-[850px]:w-[160px] lg:w-[120px] xl:w-[200px] text-gray-500 text-[13px] text-ellipsis whitespace-nowrap overflow-hidden ">
              {address}
            </span>

            <span className="text-gray-500 text-[13px]">{phoneNumber}</span>
          </div>
        </div>
        <div>{order}</div>
        <div>{moment(createdAt).format("LL")}</div>

        <div className="flex space-x-1 text-[20px] text-gray-300 cursor-pointer">
          <div
            onClick={() => {
              seteditUser(!iseditUser);
            }}
          >
            <FiEdit3 />
          </div>

          <div
            onClick={() => {
              removeUser();
            }}
          >
            <RiDeleteBin6Line />
          </div>
        </div>
      </div>

      <EditUser
        vname={name}
        heading={"Edit Customer"}
        title={"Customer Title"}
        phoneNumber={phoneNumber}
        locAddress={address}
        userId={userId}
        id={id - 1}
        telephone={"Customer Phone Number"}
        mail={"Customer Email"}
        location={"Customer Address"}
        type={"Customer"}
        iseditUser={iseditUser}
        seteditUser={seteditUser}
      />

      <DeleteModal
        isdelete={isdelete}
        setisdelete={setisdelete}
        token={token}
        id={userId}
        data={customers}
        deleteFn={deleteUser}
        dispatch={dispatch}
        getData={getCustomers}
      />
    </div>
  );
};

export default CustomerWidget;
