import React, { useEffect, useState } from "react";
import Chart from "./chart";
import { useSelector } from "react-redux";
import { revenueGraph } from "../../../Utils/api";
import DatatoCsv from "components/exporttoCSV/datatoCsv";
import { getNairaEquivalent } from "Utils/lib/helper";

const BarChart = () => {
  const { token } = useSelector((state) => state.user);
  const [xAxis, setxAxis] = useState([]);
  const [yAxis, setyAxis] = useState([]);
  const [stat, setstat] = useState([]);

  let list = [];

  useEffect(() => {
    async function getChart() {
      const months = [];
      const revenues = [];
      await revenueGraph(token)
        .then((res) => {
          console.log(res);
          const { data } = res.data;
          setstat(data);
          // eslint-disable-next-line react-hooks/exhaustive-deps

          console.log(data);
          data?.forEach(({ date, revenue }) => {
            const month = new Date(date);
            months.push(month.toLocaleString("en-US", { month: "short" }));
            revenues.push(getNairaEquivalent(revenue));
          });
          setxAxis(months);
          setyAxis(revenues);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getChart();
  }, [token]);

  const options = {
    //responsive: true,
  };
  const labels = xAxis;

  const data = {
    labels,
    datasets: [
      {
        data: yAxis,
        backgroundColor: ["#FF5B5B", "#F7C604"],
        paddingLeft: "10px",
      },
    ],
  };
  stat?.forEach(({ date, revenue }) => {
    list.push({
      Month: new Date(date).toLocaleString("en-US", { month: "long" }),
      Revenue: revenue.toFixed(2),
    });
  });

  return (
    <div className="bg-white space-y-3  h-[350px] py-4 rounded-lg ">
      <div className="flex px-6 justify-between items-center">
        <span className="font-semibold text-lg">Revenue</span>
        <DatatoCsv list={list} filename="Jekaeat_Monthly_Revenue_Data" />
      </div>
      <Chart options={options} data={data} />
    </div>
  );
};

export default BarChart;
