import React from "react";
import { CiSearch } from "react-icons/ci";
import { BsBellFill, BsChatLeftDots } from "react-icons/bs";
import { FiMenu } from "react-icons/fi";
import hijaby from "../../assets/hijaby.jpg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const TopNav = ({ setisNav, isNav, isExit, firstElement }) => {
  const { currentUser } = useSelector((state) => state.user);
  return (
    <>
      <div
        ref={firstElement}
        className={`right fixed z-[40] top-0 md:ml-[250px] min-[1024px]:float-right bg-gray-100  py-6 px-8 flex justify-between items-center ${
          isExit ? "" : ""
        }`}
      >
        <div
          onClick={() => {
            setisNav(!isNav);
          }}
          className="min-[1024px]:hidden "
        >
          <FiMenu className="text-[25px] text-gray-400" />
        </div>
        <div className="w-[60%]  h-10 rounded-md relative">
          <input
            type="text"
            placeholder="search"
            className="px-6 bg-white border w-full h-full rounded-md border-gray-200 outline-none"
          />
          <span className="absolute text-[20px] text-gray-300 right-[24px] top-[10px]">
            <CiSearch />
          </span>
        </div>
        <div className="grid grid-cols-2 gap-4 xl:gap-7">
          <div className="p-2 bg-[#2D9CDB] bg-opacity-[0.15] relative rounded-md max-[890px]:hidden">
            <BsBellFill className="text-[25px] text-[#2D9CDB]" />
            <span className="absolute bg-[#2D9CDB] text-white top-[-6px] right-0 rounded-full px-[8px] text-[10px] flex justify-center items-center">
              <span>1</span>
            </span>
          </div>
          <Link
            to="/feedback"
            className="p-2 bg-[#5E6C93] bg-opacity-[0.15] relative rounded-md max-[890px]:hidden"
          >
            <BsChatLeftDots className="text-[25px] text-[#5E6C93]" />
            
          </Link>
        </div>

        <div className="border-l border-gray-300 space-x-3 flex px-3 items-center">
          <span>{`Hello, ${
            currentUser?.fullName?.split(" ")[0] || "admin"
          }`}</span>
          <span className="w-[38px] flex items-center justify-center border h-[38px] rounded-full object-cover">
          <h2 className="text-[#7bb43e] text-lg font-medium">{currentUser?.fullName?.split(" ")[0].slice(0,1)}</h2>
          </span>
        </div>
      </div>
      {/***observer */}
      <div
        className={`right min-[1024px]:float-right bg-gray-100 bg-opacity-[0.95]  py-6 px-8 flex justify-between items-center ${
          isExit ? "fixed z-[35] top-0 right-0" : "hidden"
        }`}
      >
        <div
          onClick={() => {
            setisNav(!isNav);
          }}
          className="min-[1024px]:hidden "
        >
          <FiMenu className="text-[25px] text-gray-400" />
        </div>
        <div className="w-[60%]  h-10 rounded-md relative">
          <input
            type="text"
            placeholder="search"
            className="px-6 bg-white border w-full h-full rounded-md border-gray-200 outline-none"
          />
          <span className="absolute text-[20px] text-gray-300 right-[24px] top-[10px]">
            <CiSearch />
          </span>
        </div>
        <div className="grid grid-cols-2 gap-4 xl:gap-7">
          <div className="p-2 bg-[#2D9CDB] bg-opacity-[0.15] relative rounded-md max-[890px]:hidden">
            <BsBellFill className="text-[25px] text-[#2D9CDB]" />
            <span className="absolute bg-[#2D9CDB] text-white top-[-6px] right-0 rounded-full px-[8px] text-[10px] flex justify-center items-center">
              <span>1</span>
            </span>
          </div>
          <div className="p-2 bg-[#5E6C93] bg-opacity-[0.15] relative rounded-md max-[890px]:hidden">
            <BsChatLeftDots className="text-[25px] text-[#5E6C93]" />
            <span className="absolute bg-[#5E6C93] top-[-3px] right-0 text-white rounded-full  px-[8px] text-[10px] flex justify-center items-center">
              <span>1</span>
            </span>
          </div>
        </div>

        <div className="border-l border-gray-300 space-x-3 flex px-3 items-center">
          <span>{`Hello, ${
            currentUser?.fullName?.split(" ")[0] || "admin"
          }`}</span>
          <span className="w-[38px] h-[38px] rounded-full">
            <img className="w-full h-full rounded-full" src={hijaby} alt="" />
          </span>
        </div>
      </div>
    </>
  );
};

export default TopNav;
