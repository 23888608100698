import React, { useState, useEffect, useRef } from "react";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import order from "../../assets/svg/order.svg";
import delivered from "../../assets/svg/delivered.svg";
import cancelled from "../../assets/svg/cancelled.svg";
import revenue from "../../assets/svg/revenue.svg";
import { useSelector } from "react-redux";
import { getOrderStat } from "../../Utils/api";
import { getNairaEquivalent } from "Utils/lib/helper";
const DashboardCard = () => {
  const { token } = useSelector((state) => state.user);
  const slide = useRef();
  const [isprev, setisprev] = useState(false);
  const [isnext, setisnext] = useState(true);
  const [data, setdata] = useState();

  console.log(token);

  useEffect(() => {
    async function showStat() {
      await getOrderStat(token)
        .then((res) => {
          console.log(res.data);
          const { data } = res.data;
          console.log(data);
          setdata(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    showStat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function prev() {
    // e.stopPropagation()

    //console.log('window.scrollWidth')
    console.log(slide.current.scrollLeft);
    console.log(slide.current.scrollWidth);
    console.log(slide.current.offsetWidth);
    slide.current.scrollBy({
      left: -150,
      behavior: "smooth",
    });
  }

  function next() {
    //e.stopPropagation()
    //console.log('window.scrollWidth')

    console.log(slide.current.scrollWidth);
    console.log(slide.current.offsetWidth);
    slide.current.scrollBy({
      left: 150,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    function scrollEl() {
      console.log("Slide");
      if (slide.current.scrollLeft === 0) {
        setisprev(false);
      } else {
        setisprev(true);
      }

      if (
        slide.current.scrollLeft + slide.current.offsetWidth >=
        slide.current.scrollWidth
      ) {
        setisnext(false);
      } else {
        setisnext(true);
      }
    }

    slide.current?.addEventListener("scroll", scrollEl);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => slide.current?.removeEventListener("scroll", scrollEl);
  }, [slide.current?.scrollLeft]);

  return (
    <div className="w-full  py-4 overflow-hidden relative">
      <div
        onClick={prev}
        className={
          isprev
            ? "absolute left-0 top-[40%] z-[20] text-[25px] p-1 rounded-full bg-white text-[#7bb43e]"
            : "hidden"
        }
      >
        <MdNavigateBefore />
      </div>
      <div
        onClick={next}
        className={
          isnext
            ? "absolute right-0 top-[40%] z-[20] text-[25px] p-1 rounded-full bg-white text-[#7bb43e]"
            : "hidden"
        }
      >
        <MdNavigateNext />
      </div>
      <div ref={slide} className="w-full h-full overflow-x-auto hide-scroll ">
        <div className="min-w-max h-full flex items-center gap-5 ">
          <div className="w-[240px] h-[120px] flex items-center justify-center space-x-3  rounded-lg bg-white p-3">
            <div className="w-[45px]">
              <img src={order} alt="" className="w-full" />
            </div>

            <div className="flex flex-col justify-start space-y-[3%]">
              <div className="font-semibold text-2xl">{data?.totalOrders}</div>
              <div>Total Order</div>
            </div>
          </div>

          <div className="w-[240px] h-[120px] flex items-center justify-center space-x-3 rounded-lg bg-white p-3">
            <div className="w-[45px]">
              <img src={delivered} alt="" className="w-full" />
            </div>

            <div className="flex flex-col justify-start space-y-[3%]">
              <div className="font-semibold text-2xl">
                {data?.deliveredOrders}
              </div>
              <div>Total Delivered</div>
            </div>
          </div>
          <div className="w-[240px] h-[120px] flex items-center justify-center space-x-3 rounded-lg bg-white p-3">
            <div className="w-[45px]">
              <img src={cancelled} alt="" className="w-full" />
            </div>

            <div className="flex flex-col justify-start space-y-[3%]">
              <div className="font-semibold text-2xl">
                {data?.canceledOrders}
              </div>
              <div>Total Cancelled</div>
            </div>
          </div>
          <div className="w-[240px] h-[120px] flex items-center justify-center space-x-3 rounded-lg bg-white p-3">
            <div className="w-[45px]">
              <img src={revenue} alt="" className="w-full" />
            </div>

            <div className="flex flex-col justify-start space-y-[3%]">
              <div className="font-semibold text-2xl">
                {getNairaEquivalent(data?.totalRevenue)?.toLocaleString()}
              </div>
              <div>Total Revenue</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
