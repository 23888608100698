import React from "react";
import { MdNavigateBefore } from "react-icons/md";
import vendorbg from "../../../assets/png/vendorbg.jpeg";

import { useNavigate } from "react-router-dom";
const VendorHeading = ({ name, image, isExit, firstElement }) => {
  const navigate = useNavigate();
  return (
    <>
      <div
       ref={firstElement}
      >
        <div className="flex justify-start items-center">
          <p className="font-semibold text-lg ">Vendor</p>
        </div>
        <div className="mt-3 w-full rounded-t-md relative text-white h-[140px]">
          <img
            className="w-full h-full rounded-t-md object-cover"
            src={image || vendorbg}
            alt=""
          />

          <div className="absolute inset-0 w-full rounded-t-md  bg-black bg-opacity-50">
            <div className="justify-between py-4 items-center flex ">
              <div
                onClick={() => {
                  navigate(-1);
                }}
                className="cursor-pointer flex space-x-2 items-center"
              >
                <MdNavigateBefore className="text-[22px]" />
                <span>Back</span>
              </div>
              <div className="font-semibold  text-lg">{name}</div>
              <div className="bg-none w-3 h-3"></div>
            </div>
          </div>
        </div>
      </div>
      {/**observer */}
      <div>
        <div
          className={` rounded-b-md  text-white h-[50px] ${
            isExit ? "fixed z-[35] right-0 top-[91px] px-8 right min-[1024px]:float-right" : "hidden"
          }`}
        >
          <img
            className="w-full h-full rounded-b-md object-cover filters"
            src={vendorbg}
            alt=""
          />

          <div className="absolute inset-0 w-full px-8 rounded-b-md ">
            <div className="justify-between py-[15px] items-center flex ">
              <div
                onClick={() => {
                  navigate(-1);
                }}
                className="cursor-pointer flex space-x-2 items-center"
              >
                <MdNavigateBefore className="text-[22px]" />
                <span>Back</span>
              </div>
              <div className="font-semibold  text-lg">{name}</div>
              <div className="bg-none w-3 h-3"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorHeading;
