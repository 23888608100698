import React, { useState, useRef, useCallback } from "react";

import { HiOutlineArrowLongRight } from "react-icons/hi2";
import Container from "../../container/container";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import VendorHeading from "../vendorheading/vendorHeading";
import { useEffect } from "react";
import { singleVendor } from "../../../Utils/api";
import { useSelector } from "react-redux";
const VendorDetail = () => {
  const { id } = useParams();
  const { token } = useSelector((state) => state.user);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isExit, setisExit] = useState(false);
  const observeEl = useRef();
  const [stat, setstat] = useState();
  const [vendor, setvendor] = useState();

  /// Get the exiting element
  const firstElement = useCallback((node) => {
    observeEl.current = new IntersectionObserver((entries) => {
      if (!entries[0].isIntersecting) {
        console.log("not visible");
        setisExit(true);
      } else {
        setisExit(false);
      }
    });

    if (node) observeEl.current.observe(node);
  }, []);

  useEffect(() => {
    async function getSingleVendor() {
      await singleVendor(id, token)
        .then((res) => {
          console.log(res);
          const { data } = res.data;
          const { statistics, vendor } = data;
          setvendor(vendor);
          setstat(statistics);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getSingleVendor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container>
      <div className="w-full h-full">
        <VendorHeading
          name={vendor?.businessName || state?.name}
          isExit={isExit}
          firstElement={firstElement}
          image={vendor?.profileImage?.url}
        />
        <div className="py-3 px-6 mb-[50px] flex justify-between bg-white  w-full h-fit">
          <div className="flex flex-col justify-start space-y-3">
            <div className="space-y-2 flex flex-col">
              <span className="font-medium ">Phone Number</span>
              <span className="text-sm">{vendor?.phoneNumber}</span>
            </div>
            <div className="space-y-2 flex flex-col">
              <span className="font-medium ">Email</span>
              <span className="text-sm">{vendor?.email}</span>
            </div>
            <div className="grid grid-col-1 space-y-2">
              <div className="font-medium ">Address</div>
              <div className="text-sm">
                {`${vendor?.restaurantLocation?.address || ""} ${
                  vendor?.restaurantLocation?.city || ""
                }`}
              </div>
            </div>
            {vendor?.paymentSettlement && (
              <div className="space-y-2 py-2 border-t border-b">
                <div className="font-medium underline">Payment Settlement</div>

                <div className="grid grid-col-1 space-y-2">
                  <div className="font-medium ">Bank</div>
                  <div className="text-sm">
                    {`${vendor?.paymentSettlement?.account?.bank || ""} `}
                  </div>
                </div>

                <div className="grid grid-col-1 space-y-2">
                  <div className="font-medium ">Account Name</div>
                  <div className="text-sm">
                    {`${
                      vendor?.paymentSettlement?.account?.accountName || ""
                    } `}
                  </div>
                </div>

                <div className="flex items-center space-x-2">
                  <div className="font-medium ">Account Number:</div>
                  <div className="text-sm">
                    {`${
                      vendor?.paymentSettlement?.account?.accountNumber || ""
                    } `}
                  </div>
                </div>

                <div className="flex items-center space-x-2">
                  <div className="font-medium ">Periodic Payment:</div>
                  <div className="text-sm">
                    {`${vendor?.paymentSettlement?.period || ""} `}
                  </div>
                </div>
              </div>
            )}
            <div className="space-y-2 flex flex-col">
              <span className="font-medium ">Transaction</span>
              <span className="text-sm">{`₦${(
                stat?.revenue || 0
              )?.toLocaleString()}`}</span>
            </div>
          </div>
          {/** */}
          <div className="flex flex-col space-y-3 w-[50%] xl:w-[40%] border-b pb-2">
            <div className="space-y-2 flex flex-col ">
              <div className="justify-between flex items-center">
                <span className="font-medium ">Meal Category</span>
                <button
                  onClick={() => {
                    navigate(`/vendor/${id}/meal-category`, {
                      state: {
                        name: vendor?.businessName || state?.name,
                      },
                    });
                  }}
                  className="border border-[#7bb34e] text-sm p-1 text-[#7bb34e] text-[13px] rounded-md flex items-center justify-center space-x-1"
                >
                  <span>View List</span>
                  <HiOutlineArrowLongRight className="text-[18px]" />
                </button>
              </div>

              <span className="text-sm">Meal Pack</span>
              <span className="text-sm">Side Meal</span>
              <span className="text-sm">Main Meal</span>
            </div>

            <div className="space-y-2 flex flex-col">
              <div className="justify-between flex items-center">
                <span className="font-medium ">Number of Completed Orders</span>
                <button
                  onClick={() => {
                    navigate(`/vendor/${id}/completedorders`, {
                      state: {
                        name: vendor?.businessName || state?.name,
                      },
                    });
                  }}
                  className="border border-[#7bb34e] text-sm p-1 text-[13px] text-[#7bb34e] rounded-md flex items-center justify-center space-x-1"
                >
                  <span>View Orders</span>
                  <HiOutlineArrowLongRight className="text-[18px]" />
                </button>
              </div>
              <span>{stat?.completedOrders}</span>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default VendorDetail;
