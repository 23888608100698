import React, { useState, useRef, useCallback, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import VendorHeading from "../vendor/vendorheading/vendorHeading";
import Container from "../container/container";
import { useSelector } from "react-redux";
import Orders from "./orders";
import { successfulOrders, singleVendor } from "../../Utils/api";
const CompletedOrders = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const [isExit, setisExit] = useState(false);
  const observeEl = useRef();
  const [data, setdata] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [nextPageLoad, setNextPageLoad] = useState(false);
  const [isEmpty, setisEmpty] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [initialstart, setinitialstart] = useState("");
  const [initialending, setinitialending] = useState("");
  const [vendor, setvendor] = useState();

  useEffect(() => {
    async function getSingleVendor() {
      await singleVendor(id, token)
        .then((res) => {
          console.log(res);
          const { data } = res.data;
          const { vendor } = data;
          setvendor(vendor);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getSingleVendor();
  }, [id, token]);

  useEffect(() => {
    if (initialending !== endDate || initialstart !== startDate) {
      setPage(1);
    }

    const payload = {
      period: startDate && endDate ? "custom" : "",
      startDate,
      endDate,
    };
    async function getAllOrders() {
      if (isEmpty) return;
      if (page === 1) {
        setLoading(true);
      } else {
        setNextPageLoad(true);
      }
      await successfulOrders(id, payload, page, token)
        .then((res) => {
          console.log(res);
          setLoading(false);
          setNextPageLoad(false);
          setinitialstart(startDate);
          setinitialending(endDate);

          const { items } = res.data.data;
          if (items.length === 0) {
            setisEmpty(true);
            return;
          }
          setdata((prev) => [...prev, ...items]);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    getAllOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, startDate, endDate]);
  const totalamount = data?.reduce((acc, curr) => acc + curr.total || 0, 0);

  /// Get the exiting element
  const firstElement = useCallback((node) => {
    observeEl.current = new IntersectionObserver((entries) => {
      if (!entries[0].isIntersecting) {
        console.log("not visible");
        setisExit(true);
      } else {
        setisExit(false);
      }
    });

    if (node) observeEl.current.observe(node);
  }, []);
  return (
    <Container>
      <div className="w-full h-full">
        <VendorHeading
          name={state?.name}
          isExit={isExit}
          firstElement={firstElement}
          image={vendor?.profileImage?.url}
        />
        <div className="py-3 px-6 mb-[50px]  flex flex-col justify-start space-y-3 bg-white rounded-b-md w-full overflow-hidden h-fit">
          <div className="flex justify-start items-center">
            <p className="font-semibold text-lg ">Sales</p>
          </div>
          <Orders
            id={"S/N"}
            title={"Food Title"}
            data={data}
            page={page}
            totalTransactions={data?.length || 0}
            setPage={setPage}
            isEmpty={isEmpty}
            totalamount={totalamount || 0}
            loading={loading}
            setstartDate={setstartDate}
            setendDate={setendDate}
            nextPageLoad={nextPageLoad}
          />
        </div>
      </div>
    </Container>
  );
};

export default CompletedOrders;
