import * as type from "../Actions/Types";

const initailState = {
  riders:[],
};
const Rider = (state = initailState, action) => {
  switch (action.type) {
    case type.GET_RIDERS:
      return {
        ...state,
        riders: action.payload,
      };


    default:
      return state;
  }
};

export default Rider;
