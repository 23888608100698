import React, { useState, useEffect } from "react";
import MealWidget from "../widgets/mealWidget";
import { useDispatch, useSelector } from "react-redux";
import { getVendorMeals } from "../../../Utils/api";
import load from "../../../assets/svg/loading.svg";
import empty from "../../../assets/png/emptyorder.png";
import { getmainmeal } from "../../../Redux/Actions/ActionCreators";
import { getNairaEquivalent } from "Utils/lib/helper";
const MainMeal = ({ active, userId }) => {
  const { token } = useSelector((state) => state.user);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { mainmeal } = useSelector((state) => state.meal);
  useEffect(() => {
    const handleMeal = async () => {
      setLoading(true);
      await getVendorMeals(userId, "food_stack", "main_meal", token)
        .then((res) => {
          console.log(res);
          setLoading(false);
          const { data } = res.data;
          setdata(data);
          dispatch(getmainmeal(data));
        })
        .catch((err) => {
          console.log(err);
        });
    };

    handleMeal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className={` ${active === 1 ? "" : "hidden"}`}>
        {loading && (
          <div className="w-full h-[300px] flex justify-center items-center">
            <img src={load} alt="" />
          </div>
        )}
        {!loading && data?.length === 0 && (
          <div className="w-full h-[300px] flex justify-center items-center">
            <span className="w-[200px] h-[200px]">
              <img className="w-full h-full" src={empty} alt="" />
            </span>
          </div>
        )}
        {!loading &&
          data?.length !== 0 &&
          mainmeal?.map(
            ({ unitPrice, image, category, type, name, _id }, idx) => {
              return (
                <div key={idx}>
                  <MealWidget
                    name={name}
                    unitPrice={getNairaEquivalent(unitPrice)}
                    image={image?.url}
                    id={idx + 1}
                    cat={category}
                    type={type}
                    mealId={_id}
                  />
                </div>
              );
            }
          )}
      </div>
    </>
  );
};
export default MainMeal;
