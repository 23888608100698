import React from "react";
import { CSVLink } from "react-csv";
import { TiExport } from "react-icons/ti";

const DatatoCsv = ({ list, filename }) => {
  return (
    <CSVLink data={list} filename={filename}>
      <button className="border flex items-center space-x-2 p-1 text-white text-[13px] rounded-md bg-[#7bb34e] ">
        <span className="text-[17px]">
          <TiExport />
        </span>
        <span>Export to CSV</span>
      </button>
    </CSVLink>
  );
};

export default DatatoCsv;
