import React, { useState, useEffect } from "react";
import Orders from "./orders";
import Container from "../container/container";
import { allOrders } from "../../Utils/api";

import { useSelector } from "react-redux";
const Transaction = () => {
  const [data, setdata] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [nextPageLoad, setNextPageLoad] = useState(false);
  const [isEmpty, setisEmpty] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [initialstart, setinitialstart] = useState("");
  const [initialending, setinitialending] = useState("");
  ///2023-06-25

  useEffect(() => {
    if (initialending !== endDate || initialstart !== startDate) {
      setPage(1);
    }

    const payload = {
      period: startDate && endDate ? "custom" : "",
      startDate,
      endDate,
    };
    async function getAllOrders() {
      if (isEmpty) return;
      if (page === 1) {
        setLoading(true);
      } else {
        setNextPageLoad(true);
      }
      await allOrders(payload, page, token)
        .then((res) => {
          console.log(res);
          setLoading(false);
          setNextPageLoad(false);
          setinitialstart(startDate);
          setinitialending(endDate);
          const { items } = res.data.data;
          if (items.length === 0) {
            setisEmpty(true);
            return;
          }
          setdata((prev) => [...prev, ...items]);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    getAllOrders();
  }, [page, startDate, endDate, initialending, initialstart, isEmpty, token]);
  const totalamount = data?.reduce((acc, curr) => acc + curr.total || 0, 0);
  return (
    <Container>
      <div className="w-full h-full">
        <div className="flex justify-start mb-3 items-center">
          <p className="font-semibold text-lg ">Transactions</p>
        </div>
        <div className="py-3 px-6 mb-[50px] flex flex-col bg-white rounded-md  w-full h-fit">
          <Orders
            data={data}
            page={page}
            totalTransactions={data?.length || 0}
            setPage={setPage}
            isEmpty={isEmpty}
            totalamount={totalamount || 0}
            loading={loading}
            setstartDate={setstartDate}
            setendDate={setendDate}
            nextPageLoad={nextPageLoad}
            id={"ID"}
            title={"Vendor"}
          />
        </div>
      </div>
    </Container>
  );
};

export default Transaction;
