import React, { useState, useEffect } from "react";
import CustomerWidget from "../customerwidget/customerWidget";

import { allCustomers, customerStat } from "../../../Utils/api";
import { useDispatch, useSelector } from "react-redux";
import empty from "../../../assets/svg/loading.svg";
import { formatNumber } from "../../../Utils/lib/formatNumber";
import { getCustomers } from "../../../Redux/Actions/ActionCreators";
import DatatoCsv from "../../exporttoCSV/datatoCsv";
import moment from "moment/moment";
import Loader from "components/UI/Loader";

const CustomerList = () => {
  const { token } = useSelector((state) => state.user);
  const [, setstat] = useState();
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [data, setdata] = useState([]);
  const [filteredCustomers, setfilteredCustomers] = useState([]);
  const { customers } = useSelector((state) => state.customer);
  const [loading, setLoading] = useState(false);
  const [nextPageLoad, setNextPageLoad] = useState(false);
  const [isEmpty, setisEmpty] = useState(false);
  const dispatch = useDispatch();
  const list = [];

  useEffect(() => {
    const searchResult = customers.filter((user) =>
      user.fullName.toLowerCase().includes(query.toLowerCase())
    );
    setfilteredCustomers(searchResult);
  }, [customers, query]);

  useEffect(() => {
    const getStat = async () => {
      await customerStat(token)
        .then((res) => {
          // console.log(res);

          const { data } = res.data;
          //console.log(data);
          setstat(data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getStat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleRequest = async () => {
      if (isEmpty) return;
      if (page === 1) {
        setLoading(true);
      } else {
        setNextPageLoad(true);
      }
      await allCustomers(page, token).then((res) => {
        // console.log(res);
        setLoading(false);
        setNextPageLoad(false);
        const { items } = res.data.data;
        if (items.length === 0) {
          setisEmpty(true);
          return;
        }
        console.log(items);
        //const users = ((prev) => {[...prev, ...items]})
        // dispatch(getCustomers(users))

        //console.log(users)

        setdata((prev) => [...prev, ...items]);
      });
    };

    handleRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    dispatch(getCustomers(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  customers?.forEach(
    ({
      deliveryAddress,
      email,
      fullName,
      phoneNumber,
      totalOrders,
      createdAt,
    }) => {
      list.push({
        fullName,
        phoneNumber,
        email,
        deliveryAddress: deliveryAddress?.address,
        totalOrders,
        joined: moment(createdAt).format("llll"),
      });
    }
  );

  return (
    <div className="w-full bg-white rounded-lg mb-[20px] p-4">
      <div className="flex justify-between items-center mb-3">
        <p className="font-semibold text-lg ">{`${formatNumber(
          customers?.length || 0
        )} Users`}</p>

        <div className="w-[400px] px-10">
          <input
            type="text"
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            placeholder="Search: Full Name...."
            className=" border p-3 rounded-xl outline-none w-full"
          />
        </div>

        <DatatoCsv list={list} filename="Jekaeat_User_Data" />
      </div>

      <div className="grid grid-cols-6 w-full items-center pl-[3px] md:pl-[5%] text-zinc-500 rounded-t-md  gap-5 font-medium py-4 bg-gray-100 ">
        <div>S/N</div>
        <div className=" col-span-2 "> Customer</div>
        <div>Total Order</div>
        <div>Joined</div>

        <div>Action</div>
      </div>
      {loading && (
        <div className="w-full h-[300px] flex justify-center items-center">
          <img src={empty} alt="" />
        </div>
      )}
      {!loading &&
        filteredCustomers?.map(
          (
            {
              deliveryAddress,
              _id,
              fullName,
              phoneNumber,
              totalOrders,
              createdAt,
            },
            idx
          ) => {
            return (
              <div key={idx}>
                <CustomerWidget
                  name={fullName}
                  createdAt={createdAt}
                  address={deliveryAddress?.address}
                  userId={_id}
                  phoneNumber={phoneNumber}
                  id={idx + 1}
                  order={totalOrders}
                />
              </div>
            );
          }
        )}

      {!loading && (
        <div className="w-full flex items-center pt-4 justify-center">
          {!isEmpty && (
            <button
              onClick={() => {
                setPage(page + 1);
              }}
              className="text-white bg-[#7bb43e] w-[200px] flex items-center justify-center rounded-xl py-2"
            >
              {nextPageLoad ? <Loader /> : <span>show more</span>}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomerList;
