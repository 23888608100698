import axios from "./useAxios";

//get order statistics
export const getOrderStat = (token) => {
  return axios.get("/orders/stats", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

// get vendor statistics
export const getVendorStats = (token) => {
  return axios.get("/vendors/stats", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//best seller
export const bestseller = (token) => {
  return axios.get("/vendors/best-sellers", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//recent sales daily weekly monthly
export const recentSales = (payload, page, token) => {
  return axios.get(`/orders?period=${payload}&limit=20&page=${page}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//get all vendors
export const allvendors = (token) => {
  return axios.get(`/vendors`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//get single vendor
export const singleVendor = (id, token) => {
  return axios.get(`/vendors/${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//update vendor
export const updateVendor = (id, payload, token) => {
  console.log(payload);
  return axios.put(`/vendors/${id}`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//toggle featured
export const toggleFeatured = (id, payload, token) => {
  console.log(payload);
  return axios.patch(`/vendors/${id}/featured`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//get customer stat
export const customerStat = (token) => {
  return axios.get("/customers/stats", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//get Paginated customers
export const allCustomers = (page, token) => {
  return axios.get(`/customers?page=${page}&limit=2000`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

// //get all customers
// export const allCustomers = (page, token) => {
//   return axios.get(`/customers?limit=1000`, {
//     headers: {
//       Authorization: "Bearer " + token,
//     },
//   });
// };

//update customer
export const updateCustomer = (id, payload, token) => {
  return axios.put(`/customers/${id}`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//get all riders /riders/stats
export const allRiders = (token) => {
  return axios.get(`/riders`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//get rider stat
export const riderStat = (token) => {
  return axios.get(`/riders/stats`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//get single rider
export const singleRider = (id, token) => {
  return axios.get(`/riders/${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//update rider
export const updateRider = (id, payload, token) => {
  return axios.put(`/riders/${id}`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

// get vendor meals
export const getVendorMeals = (id, category, type, token) => {
  return axios.get(
    `/vendors/${id}/products?category=${category}&type=${type}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

//update a meal
export const updateMeal = (id, payload, token) => {
  return axios.put(`/products/${id}`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

// add a meal
export const newMeal = (id, payload, token) => {
  return axios.post(`/vendors/${id}/products`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//delete a meal
export const deleteMeal = (id, token) => {
  return axios.delete(`/products/${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

// add user
export const addUser = (payload, token) => {
  return axios.post(`/users`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//delete user
export const deleteUser = (id, token) => {
  return axios.delete(`/users/${id}/`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

// all orders
export const allOrders = (payload, page, token) => {
  //const {period, startDate, endDate} = payload
  return axios.get(
    `/orders?period=${payload?.period}&startDate=${payload?.startDate}&endDate=${payload?.endDate}&page=${page}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

// vendor || rider completed orders

export const successfulOrders = (id, payload, page, token) => {
  return axios.get(
    `/users/${id}/orders?period=${payload?.period}&startDate=${payload?.startDate}&endDate=${payload?.endDate}&page=${page}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

//update user image
export const updateImage = (id, form, token) => {
  return axios.patch(`/users/${id}/profile-image`, form, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//revenue graph
export const revenueGraph = (token) => {
  return axios.get(`/dashboard/revenue-graph/yearly`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//all payments
export const allPayments = (token, page) => {
  return axios.get(`/payouts?page=${page}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//paystack balance
export const paystackBalance = (token) => {
  return axios.get("/payouts/paystack-balance", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//Retry Payout

export const reprocessPayout = (id, token) => {
  return axios.put(
    `/payouts/${id}/processPayout`,
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};
//Feedback
export const getFeedback = (token) => {
  return axios.get("/feedbacks", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
//Notifications
export const getActiveNotifications = (token) => {
  return axios.get("/custom-notifications/", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
//Notifications
export const sheduleNotification = (payload, token) => {
  return axios.post("/custom-notifications/", payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
//Notifications
export const deleteNotification = (id, token) => {
  return axios.delete(`/custom-notifications/${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
//Notifications
export const pauseNotification = (id, token) => {
  return axios.patch(
    `/custom-notifications/${id}/stop`,
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};
//Settings
export const getOrderingStatus = (token) => {
  return axios.get(`/settings/ordering-status/`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
//Settings
export const toggleOrderingStatus = (token) => {
  return axios.put(
    `/settings/toggle-ordering`,
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};
//Open and Close shop
export const openAndCloseShop = (id, token) => {
  return axios.patch(
    `vendors/${id}/toggle-shop-closed`,
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};
