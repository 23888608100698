import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

const Chart = ({ options, data }) => {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

  return <Bar options={options} data={data} />;
};
export default Chart;
