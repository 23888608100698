import React from "react";
import ResponsivePagination from "react-responsive-pagination";
import "./pagination.css"; // see pagination.css example below

export default function Pagination({
  totalItems,
  currentPage,
  token,
  fetchAllPAyments,
}) {
  const totalPages = totalItems;

  function handlePageChange(page) {
    fetchAllPAyments(token, page);
    // ... do something with `page`
  }
  console.log(totalItems, currentPage);

  return (
    <div className="p-2 bg-white rounded-xl my-5">
      <ResponsivePagination
        total={totalPages}
        current={currentPage}
        onPageChange={(page) => handlePageChange(page)}
      />
    </div>
  );
}
