import React, { useState, useRef, useCallback } from "react";
import TopNav from "./topNav";
import SideNav from "./sideNav";
import AddUser from "../updateuser/addUser";
import MessageCard from "../cards/messageCard";

const Container = ({ children }) => {
  const [isNav, setisNav] = useState(false);
  const [isadduser, setadduser] = useState(false);
  const [isExit, setisExit] = useState(false);
  const observeEl = useRef();

  /// Get the exiting element
  const firstElement = useCallback((node) => {
    observeEl.current = new IntersectionObserver((entries) => {
      if (!entries[0].isIntersecting) {
        console.log("not visible");
        setisExit(true);
      } else {
        setisExit(false);
      }
    });

    if (node) observeEl.current.observe(node);
  }, []);
  return (
    <div className="w-full h-full pb-[50px]  bg-gray-100 text-zinc-700">
      <TopNav
        isNav={isNav}
        setisNav={setisNav}
        isExit={isExit}
        firstElement={firstElement}
      />
      <AddUser isadduser={isadduser} setadduser={setadduser} />
      <MessageCard />
      <div className="right min-[1010px]:float-right bg-gray-100 pt-24  px-8 h-screen overflow-y-auto">
        {children}
      </div>
      <SideNav
        isadduser={isadduser}
        setadduser={setadduser}
        isNav={isNav}
        setisNav={setisNav}
      />
    </div>
  );
};

export default Container;
