import React from "react";
import dropdown from "../../assets/svg/dropdown.svg";
import VendorCards from "../cards/vendorCards";
import VendorList from "./vendorlist/vendorList";
import Container from "../container/container";

const VendorBoard = () => {
  return (
    <Container>
      <div className="w-full h-full">
        <div className="flex justify-between items-center">
          <p className="font-semibold text-lg ">Vendor</p>
          <p className="border flex justify-between w-[150px] rounded-md items-center text-sm p-1 ">
            <span>Last month</span>
            <span>
              <img src={dropdown} alt="dropdown" />
            </span>
          </p>
        </div>
        <VendorCards />
        <VendorList />
      </div>
    </Container>
  );
};

export default VendorBoard;
