import React from "react";
import logo from "../../assets/svg/logo.svg";
import { GoHome } from "react-icons/go";
import { AiOutlineUser } from "react-icons/ai";
import { IoMdBicycle } from "react-icons/io";
import { LuUsers } from "react-icons/lu";
import { BsMenuButtonWideFill } from "react-icons/bs";
import { HiOutlinePlusSm } from "react-icons/hi";
import { BsGearFill } from "react-icons/bs";
import { MdCopyright, MdPayment, MdNotificationsActive } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

const SideNav = ({ isNav, setisNav, isadduser, setadduser }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const SideBarButton = ({ name, route, icon }) => {
    return (
      <div
        onClick={() => {
          navigate(route);
        }}
        className={`relative flex space-x-3 justify-start items-center font-medium  py-2 ${
          pathname === route
            ? "bg-[#7BBE4A] bg-opacity-[0.15] rounded-lg text-[#7BBE4A]"
            : ""
        } `}
      >
        {pathname === route && (
          <span className="w-1 rounded-lg h-full absolute left-[-44px] bg-[#7BBE4A]"></span>
        )}
        <span>{icon}</span>
        <span className="mt-1"> {name}</span>
      </div>
    );
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setisNav(!isNav);
      }}
      className={`fixed z-[37] inset-y-0 left-0 h-screen let swipeInLeft ${
        isNav
          ? "w-full bg-black bg-opacity-50 min-[1024px]:w-[250px]"
          : "max-[1024px]:hidden w-[250px] "
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=" px-8 py-2 flex flex-col relative items-center h-full w-[250px] cursor-pointer  bg-white"
      >
        <div className="w-[130px] h-[90px] mr-[60px]">
          <img src={logo} alt="" />
        </div>

        <div className="my-1 pl-3 flex flex-col w-full space-y-1">
          <SideBarButton
            name="Dashboard"
            route="/home"
            icon={<GoHome className="text-[25px]" />}
          />
          <SideBarButton
            name="Vendor"
            route="/vendor"
            icon={<AiOutlineUser className="text-[25px]" />}
          />
          <SideBarButton
            name="Customer"
            route="/customer"
            icon={<IoMdBicycle className="text-[25px]" />}
          />
          <SideBarButton
            name="Rider"
            route="/rider"
            icon={<LuUsers className="text-[25px]" />}
          />
          <SideBarButton
            name="Transaction"
            route="/transaction"
            icon={<BsMenuButtonWideFill className="text-[25px]" />}
          />
          <SideBarButton
            name="Payment"
            route="/payment"
            icon={<MdPayment className="text-[25px]" />}
          />
          <SideBarButton
            name="Notifications"
            route="/notifications"
            icon={<MdNotificationsActive className="text-[25px]" />}
          />
          <SideBarButton
            name="Settings"
            route="/settings"
            icon={<BsGearFill className="text-[25px]" />}
          />
        </div>

        <div className="w-full absolute bottom-0 flex flex-col justify-center items-center space-y-1 inset-x-0 px-6 py-2">
          <div className="relative w-[202px] h-[130px] rounded-lg overflow-hidden">
            <div className="bg-[#7BBE4A] h-full w-full  rounded-lg"></div>
            <div className="w-[50%] justify-start items-center absolute left-5 inset-y-0 py-1 flex flex-col space-y-3">
              <div className="text-[12px] text-white">
                Please use the button below to create a user
              </div>
              <button
                onClick={() => {
                  setadduser(!isadduser);
                }}
                className="border-0  bg-white p-2 rounded-md flex space-x-1 items-center justify-center shadow-lg "
              >
                <HiOutlinePlusSm className="text-[16px] " />
                <span>Add User</span>
              </button>
            </div>

            <div className="gradient rounded-full w-[160px] h-[160px] bottom-[-91px] right-[-75px] absolute"></div>
          </div>

          <div className="w-full text-[12px]">
            <div className="text-zinc-600">Jekaeat Admin Dashboard</div>
            <div className="flex space-x-2 items-center text-zinc-400">
              <MdCopyright />
              <span>{`${new Date().getFullYear()} All Rights Reserved`}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
