import React, { useState } from "react";
import Loader from "../../UI/Loader";
import choosepics from "../../../assets/svg/choosepics.svg";
import { IoIosClose } from "react-icons/io";
import dropdown from "../../../assets/svg/dropdown.svg";
import { updateMeal } from "../../../Utils/api";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { updateAMeal } from "../../../Redux/Actions/ActionCreators";
import Compressor from "compressorjs";
import { uploadFile } from "react-s3";
const EditMeal = ({
  iseditMeal,
  seteditMeal,
  id,
  fname,
  image,
  unitPrice,
  cat,
  types,
  mealId,
}) => {
  const { token } = useSelector((state) => state.user);
  const [mealimage, setmealimage] = useState(image);
  const [uploadedImage, setuploadedImage] = useState(image);
  const [meal, setMeal] = useState(cat);
  const [showMeal, setShowMeal] = useState(false);
  const [category, setcategory] = useState(cat);
  const [type, settype] = useState(types);
  const dispatch = useDispatch();
  const [name, setName] = useState(fname);
  const [showType, setShowMealType] = useState(false);
  const meals = useSelector((state) => state.meal);
  const [price, setPrice] = useState(unitPrice);
  const [loading, setLoading] = useState(false);
  const S3_BUCKET = "jekaeat";
  const REGION = "us-east-1";
  const ACCESS_KEY = "AKIA4UDXBX5QCIPDRT4N";
  const SECRET_ACCESS_KEY = "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";
  window.Buffer = window.Buffer || require("buffer").Buffer;

  const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    dirName: "FoodImages" /* optional */,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  };

  console.log(type);
  console.log(category);

  const handleImageUpload = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files[0]) {
      const file = e.target.files[0];
      new Compressor(file, {
        quality: 0.8,
        width: 600,

        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(result) {
          console.log(result.size);

          uploadFile(file, config)
            .then((data) => {
              const { location } = data;
              setuploadedImage(location);
              setmealimage(location);
              // setimageloader(false);
            })
            .catch((err) => {
              //setimageloader(false);
              console.error(err);
            });
        },
      });

      // const image = URL.createObjectURL(file);
      //setuploadedImage(image);
      //setmealimage(file);
    }
  };

  const handleEditImage = () => {
    setuploadedImage(null);
    setmealimage(null);
  };
  const handleEditMeal = async () => {
    console.log({
      name,
      mealimage,
      category,
      price,
      type,
    });
    setLoading(true);
    const formData = new FormData();
    formData.append("mealImage", mealimage);
    formData.append("name", name);
    formData.append("category", category);
    formData.append("unitPrice", price);
    formData.append("isAvailable", true);
    type && formData.append("type", type);
    console.log(mealId);
    await updateMeal(mealId, formData, token)
      .then((res) => {
        console.log(res);
        const { data } = res.data;
        toast.success("meal updated successfully");
        const mealtype = type ? type : category;
        const meal = mealtype.toLowerCase().split(" ").join("");
        dispatch(updateAMeal(id, data, type || category, meals[meal]));
        seteditMeal(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //  console.log(cat)

  const mealType = [
    { meal: "select an option..." },
    { meal: "main_meal" },
    { meal: "side_meal" },
  ];
  const mealCategory = [
    { meal: "select an option..." },
    { meal: "meal_pack" },
    { meal: "food_stack" },
    { meal: "Others" },
  ];
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        seteditMeal(!iseditMeal);
      }}
      className={
        iseditMeal
          ? "w-full h-full bg-black bg-opacity-50 z-[70] fixed inset-0 flex items-center justify-center"
          : "hidden"
      }
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="w-[350px] h-[90%] bg-white space-y-3 rounded-md pl-4 pr-3 pt-4 pb-16 overflow-hidden relative"
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
            seteditMeal(!iseditMeal);
          }}
          className="absolute right-3 top-2"
        >
          <IoIosClose className="text-[22px]" />
        </div>
        <div className="text-lg font-medium">Edit Meal</div>

        <div className="scroll w-full h-full space-y-2 items-center overflow-y-auto">
          {!uploadedImage ? (
            <div className=" border rounded-md flex w-[98%] h-[200px] flex-col space-y-[4%] justify-center items-center py-4">
              <div className="h-8 w-8">
                <img
                  className="w-[98%] h-full object-cover"
                  src={choosepics}
                  alt="choosepics"
                />
              </div>
              <div className="flex">
                <label className="label">
                  <input
                    type="file"
                    name="file"
                    accept="image/*"
                    onChange={(e) => {
                      handleImageUpload(e);
                    }}
                  />
                  <span className="text-[#7BBE4A]">Upload user image</span>
                </label>
              </div>
            </div>
          ) : (
            <img
              src={uploadedImage}
              alt="uploadedImage"
              className="object-cover h-[200px] w-[98%]"
            />
          )}

          <div className="flex w-[98%] py-4 space-x-2 justify-center items-center">
            <button
              onClick={() => {
                handleEditImage();
              }}
              className="text-[#7bb34e] text-sm"
            >
              Edit
            </button>
          </div>
          <div className="form-group space-y-3">
            <label className="block " htmlFor="name">
              Meal Title
            </label>
            <input
              className="block form__input input-field border focus:border-gray-500 hover:border-gray-500 rounded-md focus:outline-none w-[98%] h-11 px-4"
              type="text"
              placeholder=""
              value={name}
              name="name"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="form-group space-y-3">
            <label className="block " htmlFor="price">
              Unit Price
            </label>
            <input
              className="block form__input input-field border focus:border-gray-500 hover:border-gray-500 rounded-md focus:outline-none w-[98%] h-11 px-4"
              type="number"
              placeholder=""
              value={price}
              name="price"
              onChange={(e) => {
                setPrice(e.target.value);
              }}
            />
          </div>
          <div className="space-y-3">
            <p>Meal Category</p>

            <div className=" relative">
              <div
                onClick={() => {
                  setShowMeal(!showMeal);
                }}
                id={showMeal ? "show-cat" : ""}
                className="rounded-md w-[98%] px-3 py-1 bg-white border flex justify-start items-center h-11"
              >
                <span className="text-sm">{meal || "select an option..."}</span>
              </div>
              <span
                onClick={() => {
                  setShowMeal(!showMeal);
                }}
                className="absolute h-3 w-4 right-3 top-3.5"
              >
                <img src={dropdown} alt="dropdown" />
              </span>
              {showMeal && (
                <div className="absolute inset-x-0 h-fit space-y-[3%] border-b border-l border-r w-[98%] z-30 py-3 bg-white rounded-b-md ">
                  {mealCategory.map((item, index) => {
                    return (
                      <div
                        className="w-full flex justify-start items-center transform ease-in-out transition-all duration-200"
                        key={index}
                      >
                        <span
                          onClick={() => {
                            setMeal(item.meal);
                            setcategory(item.meal);
                            setShowMeal(!showMeal);
                          }}
                          className="hover:bg-gray-100 px-3 py-2 w-full"
                        >
                          {item.meal}
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>

          {category === "food_stack" && (
            <div className="space-y-3">
              <p>Meal Type</p>

              <div className=" relative">
                <div
                  onClick={() => {
                    setShowMealType(!showType);
                  }}
                  id={showMeal ? "show-cat" : ""}
                  className="rounded-md w-[98%] px-3 py-1 bg-white border flex justify-start items-center h-11"
                >
                  <span className="text-sm">
                    {type || "select an option..."}
                  </span>
                </div>
                <span
                  onClick={() => {
                    setShowMealType(!showType);
                  }}
                  className="absolute h-3 w-4 right-3 top-3.5"
                >
                  <img src={dropdown} alt="dropdown" />
                </span>
                {showType && (
                  <div className="absolute inset-x-0 h-fit space-y-[3%] border-b border-l border-r w-[98%] z-30 py-3 bg-white rounded-b-md ">
                    {mealType.map((item, index) => {
                      return (
                        <div
                          className="w-full flex justify-start items-center transform ease-in-out transition-all duration-200"
                          key={index}
                        >
                          <span
                            onClick={() => {
                              settype(item.meal);
                              setShowMealType(!showType);
                            }}
                            className="hover:bg-gray-100 px-3 py-2 w-full"
                          >
                            {item.meal}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )}

          <button
            onClick={handleEditMeal}
            className="w-[98%] py-3 bg-[#7BBE4A] rounded-lg text-white flex justify-center items-center space-x-2"
          >
            {loading ? <Loader /> : "Add Meal"}
          </button>
        </div>
      </div>
    </div>
  );
};
export default EditMeal;
