// Action Creators
import * as type from "./Types";
import axios from "../../Utils/useAxios";
import toast from "react-hot-toast";

const GetUsersSuccess = (data) => {
  return {
    type: type.FETCH_USER_SUCCESS,
    payload: data,
  };
};

const loginSuccess = (data) => {
  return {
    type: type.LOGIN_SUCCESS,
    payload: data,
  };
};
const logout = () => {
  return {
    type: type.LOGOUT,
  };
};

const getVendors = (data) => {
  return {
    type: type.GET_VENDORS,
    payload: data,
  };
};
const getCustomers = (data) => {
  return {
    type: type.GET_CUSTOMERS,
    payload: data,
  };
};
const getRiders = (data) => {
  return {
    type: type.GET_RIDERS,
    payload: data,
  };
};

const getmainmeal = (data) => {
  return {
    type: type.GET_MAINMEAL,
    payload: data,
  };
};
const getsidemeal = (data) => {
  return {
    type: type.GET_SIDEMEAL,
    payload: data,
  };
};
const getothers = (data) => {
  return {
    type: type.GET_OTHERS,
    payload: data,
  };
};
const getmealpack = (data) => {
  return {
    type: type.GET_MEALPACK,
    payload: data,
  };
};

const updateAVendor = (id, data) => {
  return (dispatch, getState) => {
    const { vendors } = getState().vendor;
    const { completedOrders, totalOrders } = vendors[id];
    vendors[id] = { completedOrders, totalOrders, ...data };

    dispatch(getVendors(vendors));
  };
};

const updateARider = (id, data) => {
  return (dispatch, getState) => {
    const { riders } = getState().rider;
    const { cancelledOrders, completedOrders, revenue } = riders[id];
    riders[id] = { cancelledOrders, completedOrders, revenue, ...data };
    dispatch(getRiders(riders));
  };
};
const updateACustomer = (id, data) => {
  return (dispatch, getState) => {
    const { customers } = getState().customer;
    const { totalOrders } = customers[id];
    customers[id] = { totalOrders, ...data };
    dispatch(getCustomers(customers));
  };
};

const updateAMeal = (id, data, type, meals) => {
  return (dispatch) => {
    const mealtype = type.toLowerCase().split(" ").join("");
    const mealsArray = meals;
    mealsArray[id] = data;
    dispatch({ type: `GET_${mealtype.toUpperCase()}`, payload: mealsArray });

    // mainmeal[id] =data
    // dispatch(getMainmeal(mainmeal))
  };
};

const addnewMeal = (data, meals) => {
  return () => {
    //console.log(type)
    // const mealtype = type.toLowerCase().split(' ').join('')
    //console.log(getState().meal[mealtype])
    //const mealsArray = getState().meal[mealtype]
    meals.push(data);

    //dispatch({type: `GET_${mealtype.toUpperCase()}`, payload: newmealArray})
  };
};

const LoginAction = (loginParams, navigate, setLoading) => {
  return async (dispatch) => {
    setLoading(true);
    await axios
      .post("/auth/login", loginParams)
      .then((res) => {
        console.log(res.data.data);
        const { admin } = res.data.data;
        dispatch(GetUsersSuccess(admin));

        const { token } = res.data.data.accessToken;
        dispatch(loginSuccess(token));
        console.log(token);
        console.log(admin);
        navigate("/home");
        setLoading(false);
        toast.success("Login successful");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        console.log(error.response.data.error.message);
        toast.error(error.response.data.error.message);
      });
  };
};

const registration = (registrationParams, navigate, setLoading) => {
  return async (dispatch) => {
    setLoading(true);
    await axios
      .post("/auth/signup", registrationParams)
      .then((res) => {
        console.log(res.data.data);
        const { data } = res.data;
        dispatch(GetUsersSuccess(data.newVendor));
        toast.success("Registration Successful");
        navigate("/meal");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        //console.log(error.response.data.error.message);
        toast.error(error.response.data.error.message);
      });
  };
};

export {
  LoginAction,
  registration,
  loginSuccess,
  logout,
  getVendors,
  getCustomers,
  getRiders,
  getmainmeal,
  getmealpack,
  getothers,
  getsidemeal,
  updateAVendor,
  updateACustomer,
  updateARider,
  updateAMeal,
  addnewMeal,
};
