import toast from "react-hot-toast";

export default function validateFormData(toast, payload) {
  let error = "";

  // Check if 'name' is empty
  if (!payload.name) {
    error = "Name is required.";
    toast.error(error);
    return false;
  }

  // Check if 'time' is empty
  if (!payload.time) {
    error = "Time is required.";
    toast.error(error);
    return false;
  }

  // Check if 'days' is an empty array
  if (!payload.days || payload.days.length === 0) {
    error = "At least one day must be selected.";
    toast.error(error);
    return false;
  }

  // Check if 'userType' is empty
  if (!payload.userType) {
    error = "User type is required.";
    toast.error(error);
    return false;
  }

  // Check if 'notification.title' is empty
  if (!payload.notification || !payload.notification.title) {
    error = "Notification title is required.";
    toast.error(error);
    return false;
  }

  // Check if 'notification.body' is empty
  if (!payload.notification || !payload.notification.body) {
    error = "Notification body is required.";
    toast.error(error);
    return false;
  }

  return true;
}

export function errMsg(err) {
  toast.error(err.response.data.error.message);
}

export const getNairaEquivalent = (num) => {
  if (num) {
    return Number(num) / 100;
  }
  return 0;
};
