import React, { useState, useRef, useEffect, useCallback } from "react";
import Container from "../../container/container";
import MainMeal from "../mealtype/mainMeal";
import SideMeal from "../mealtype/sideMeal";
import MealPack from "../mealtype/mealPack";
import { useLocation, useParams } from "react-router-dom";
import VendorHeading from "../vendorheading/vendorHeading";
import AddMeal from "../updatemeal/addMeal";
import OtherMeal from "../mealtype/otherMeal";
import { singleVendor } from "../../../Utils/api";
import { useSelector } from "react-redux";
import DatatoCsv from "components/exporttoCSV/datatoCsv";

const MealCategory = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const { token } = useSelector((state) => state.user);
  const { mealpack, mainmeal, sidemeal, others } = useSelector(
    (state) => state.meal
  );
  // console.log(others);
  const allMeal = [...mealpack, ...mainmeal, ...sidemeal, ...others];
  let list = [];
  list.push({
    VendorId: allMeal[0]?.vendor?._id,
  });
  // eslint-disable-next-line array-callback-return
  allMeal.map((meal) => {
    list.push({
      id: meal._id,
      name: meal.name,
      category: meal.category,
      price: meal.unitPrice,
    });
  });
  const [isExit, setisExit] = useState(false);
  const observeEl = useRef();
  const [active, setActive] = useState(0);
  const [isaddMeal, setaddMeal] = useState(false);
  const [vendor, setvendor] = useState();

  useEffect(() => {
    async function getSingleVendor() {
      await singleVendor(id, token)
        .then((res) => {
          console.log(res);
          const { data } = res.data;
          const { vendor } = data;
          setvendor(vendor);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getSingleVendor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /// Get the exiting element
  const firstElement = useCallback((node) => {
    observeEl.current = new IntersectionObserver((entries) => {
      if (!entries[0].isIntersecting) {
        console.log("not visible");
        setisExit(true);
      } else {
        setisExit(false);
      }
    });

    if (node) observeEl.current.observe(node);
  }, []);

  const categories = ["Meal Pack", "Main Meal", "Side Meal", "Others"];
  return (
    <Container>
      <div className="w-full h-full">
        <VendorHeading
          name={state?.name}
          isExit={isExit}
          firstElement={firstElement}
          image={vendor?.profileImage?.url}
        />

        <div className="py-3 px-6 mb-[50px] flex flex-col bg-white  w-full h-fit">
          <div className="flex justify-between items-center">
            <p className="font-semibold text-lg ">Meal Category</p>
            <button
              onClick={() => {
                setaddMeal(!isaddMeal);
              }}
              className="border justify-center items-center p-2 border-[#7bb43e] text-[#7bb43e] text-sm rounded-md"
            >
              <span className="text-[18px]">+</span>
              <span>Add Meal</span>
            </button>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex space-x-6 items-center py-2">
              {categories.map((cat, idx) => {
                return (
                  <div
                    onClick={() => {
                      setActive(idx);
                    }}
                    key={idx}
                    className={`px-1 cursor-pointer py-4 ${
                      active === idx
                        ? "border-b-2 text-[#7bb43e] border-[#7bb43e]"
                        : ""
                    }`}
                  >
                    {cat}
                  </div>
                );
              })}
            </div>
            <div>
              {" "}
              <DatatoCsv
                list={list}
                filename={`Jekaeat___${allMeal[0]?.vendor?.businessName}__Meal_Data`}
              />
            </div>
          </div>

          <div className="grid grid-cols-5 w-full items-center pl-[3px] md:pl-[5%] bg-gray-100 text-zinc-500 rounded-t-md px-3  gap-5 font-medium py-4">
            <div>S/N</div>
            <div className="col-span-2">Food Title</div>
            <div>Unit Price</div>
            <div>Actions</div>
          </div>
          <MealPack active={active} userId={id} />
          <SideMeal active={active} userId={id} />
          <MainMeal active={active} userId={id} />
          <OtherMeal active={active} userId={id} />
        </div>
      </div>
      <AddMeal isaddMeal={isaddMeal} userId={id} setaddMeal={setaddMeal} />
    </Container>
  );
};

export default MealCategory;
