/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import SalesWidget from "../saleswidget/salesWidget";
import { recentSales } from "../../../../Utils/api";
import loadings from "../../../../assets/svg/loading.svg";
import { LoaderIcon } from "react-hot-toast";
import { useLatestSalesHook } from "Hooks";
import empty from "../../../../assets/png/emptyorder.png";
import { dateOfMonth } from "../../../../Utils/lib/stringtoDate";
import { getNairaEquivalent } from "Utils/lib/helper";
const MonthlySales = ({ active }) => {
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [paging, setPaging] = useState("");
  const [nextPageLoad, setnextPageLoad] = useState(false);

  useLatestSalesHook(
    recentSales,
    "monthly",
    page,
    setdata,
    setPaging,
    setLoading,
    setnextPageLoad
  );

  return (
    <div className={` ${active === 2 ? "" : "hidden"}`}>
      {loading && (
        <div className="w-full h-[300px] flex justify-center items-center">
          <img src={loadings} alt="" />
        </div>
      )}
      {!loading && data?.length === 0 && (
        <div className="w-full h-[300px] flex justify-center items-center">
          <span className="w-[200px] h-[200px]">
            <img className="w-full h-full" src={empty} alt="" />
          </span>
        </div>
      )}
      {!loading && data?.length !== 0 && (
        <div>
          {data?.map(
            (
              {
                packages,
                createdAt,
                meals,
                total,
                vendor,
                payment,
                status,
                deliveryInfo,
                rider,
              },
              idx
            ) => {
              return (
                <div key={idx}>
                  <SalesWidget
                    id={idx + 1}
                    food={packages[0].items
                      ?.map((item) => item?.product?.name)
                      .toString()}
                    img={vendor?.profileImage?.url}
                    price={getNairaEquivalent(total)}
                    payment={payment}
                    orderStatus={status}
                    date={dateOfMonth(createdAt)}
                    restaurant={vendor?.businessName}
                    deliveryInfo={deliveryInfo}
                    meals={meals}
                    riderInfo={rider}
                    packages={packages}
                  />
                </div>
              );
            }
          )}
        </div>
      )}
      {paging?.pageCount !== paging?.currentPage && (
        <div className="w-full h-fit mt-4 flex items-center justify-center py-4">
          <button
            onClick={() => {
              setPage(page + 1);
            }}
            className="bg-[#7ba350] rounded-md text-white font-medium flex items-center justify-center h-[36px] w-[150px]"
          >
            {nextPageLoad ? (
              <LoaderIcon className="w-8 h-8 animate-spin" />
            ) : (
              "Show more"
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default MonthlySales;
