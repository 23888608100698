import { useSelector } from "react-redux";
import { useEffect } from "react";
export async function useLatestSalesHook(
  queryFunction,
  payload,
  page,
  setdata,
  setPaging,
  setLoading,
  setnextPageLoad
) {
  const { token } = useSelector((state) => state.user);

  // handles when page changes

  useEffect(() => {
    if (page !== 1) setnextPageLoad(true);
    if (page === 1) setLoading(true);
    async function fetchData() {
      try {
        if (page === 1) setLoading(true);
        const response = await queryFunction(payload, page, token);
        //  console.log(response?.data?.data?.items);
        const { items, paging } = response?.data?.data;
        setLoading(false);
        setnextPageLoad(false); //onSuccess, stop loading next page

        //filter
        setPaging(paging);
        setdata((prev) => [...prev, ...items]);

        // return if data is empty
      } catch (error) {
        console.error(error);
        // setnextPageLoad(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
}
