import React, { useState, useEffect } from "react";
import MealWidget from "../widgets/mealWidget";
import { useSelector, useDispatch } from "react-redux";
import { getVendorMeals } from "../../../Utils/api";
import load from "../../../assets/svg/loading.svg";
import empty from "../../../assets/png/emptyorder.png";
import { getsidemeal } from "../../../Redux/Actions/ActionCreators";
import { getNairaEquivalent } from "../../../Utils/lib/helper";

const SideMeal = ({ active, userId }) => {
  const { token } = useSelector((state) => state.user);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const { sidemeal } = useSelector((state) => state.meal);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleMeal = async () => {
      setLoading(true);
      await getVendorMeals(userId, "food_stack", "side_meal", token)
        .then((res) => {
          console.log(res);
          setLoading(false);
          const { data } = res.data;
          setdata(data);
          dispatch(getsidemeal(data));
        })
        .catch((err) => {
          console.log(err);
        });
    };

    handleMeal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={` ${active === 2 ? "" : "hidden"}`}>
      {loading && (
        <div className="w-full h-[300px] flex justify-center items-center">
          <img src={load} alt="" />
        </div>
      )}
      {!loading && data?.length === 0 && (
        <div className="w-full h-[300px] flex justify-center items-center">
          <span className="w-[200px] h-[200px]">
            <img className="w-full h-full" src={empty} alt="" />
          </span>
        </div>
      )}
      {!loading &&
        data?.length !== 0 &&
        sidemeal?.map(
          ({ name, unitPrice, image, category, type, _id }, idx) => {
            return (
              <div key={idx}>
                <MealWidget
                  name={name}
                  unitPrice={getNairaEquivalent(unitPrice)}
                  image={image?.url}
                  cat={category}
                  type={type}
                  mealId={_id}
                  id={idx + 1}
                />
              </div>
            );
          }
        )}
    </div>
  );
};
export default SideMeal;
