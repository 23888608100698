import React, { useState, useEffect } from "react";

import VendorWidget from "../widgets/vendorWidget";
import empty from "../../../assets/svg/loading.svg";
import { allvendors } from "../../../Utils/api";
import { useDispatch, useSelector } from "react-redux";
import { getVendors } from "../../../Redux/Actions/ActionCreators";
import DatatoCsv from "../../exporttoCSV/datatoCsv";
const VendorList = () => {
  const { token } = useSelector((state) => state.user);
  const { vendors } = useSelector((state) => state.vendor);
  const [ismore, setismore] = useState(false);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const list = [];

  console.log(vendors);
  useEffect(() => {
    const getAllVendors = async () => {
      setLoading(true);
      await allvendors(token)
        .then((res) => {
          console.log(res);
          const { data } = res.data;
          setdata(data);
          dispatch(getVendors(data));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getAllVendors();
  }, [dispatch, token]);

  vendors?.forEach(
    ({
      fullName,
      businessName,
      restaurantLocation,
      businessAddress,
      phoneNumber,
      featured,
      email,
      totalOrders,
      completedOrders,
    }) => {
      list.push({
        fullName,
        businessName,
        Location: restaurantLocation?.address || businessAddress,
        phoneNumber,
        featured,
        email,
        totalOrders,
        completedOrders,
      });
    }
  );

  return (
    <div className="w-full bg-white rounded-lg mb-[20px] p-4">
      <div className="flex justify-between items-center mb-3">
        <p className="font-semibold text-lg ">List of Vendors</p>
        <DatatoCsv list={list} filename="Jekaeat_Vendor_Data" />
      </div>

      <div className="grid grid-cols-7 w-full items-center pl-[3px] md:pl-[5%] text-zinc-500 rounded-t-md  gap-5 font-medium py-4 bg-gray-100 ">
        <div className=" col-span-2 "> Vendor</div>
        <div>Shop-Status</div>
        <div>Total Order</div>
        <div>Delivery</div>
        <div>Action</div>
        <div>Status</div>
      </div>

      {loading && (
        <div className="w-full h-[300px] flex justify-center items-center">
          <img src={empty} alt="" />
        </div>
      )}
      {!loading &&
        data?.map(
          (
            {
              _id,
              totalOrders,
              email,
              completedOrders,
              businessName,
              featured,
              restaurantLocation,
              businessAddress,
              phoneNumber,
              profileImage,
              shopClosed,
            },
            idx
          ) => {
            return (
              <div key={idx}>
                <VendorWidget
                  name={businessName || businessName}
                  address={businessAddress || restaurantLocation?.address}
                  id={idx + 1}
                  vendorId={_id}
                  delivery={totalOrders}
                  order={completedOrders}
                  verified={featured || false}
                  phoneNumber={phoneNumber || 0}
                  email={email}
                  profileImage={profileImage?.url}
                  ismore={ismore}
                  setismore={setismore}
                  shopClosed={shopClosed}
                />
              </div>
            );
          }
        )}
    </div>
  );
};

export default VendorList;
