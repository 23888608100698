import React, { useState, useRef, useEffect, useCallback } from "react";

import { HiOutlineArrowLongRight } from "react-icons/hi2";
import Container from "../../container/container";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RiderHeading from "../riderheading/riderHeading";
import { singleRider } from "../../../Utils/api";
import { useSelector } from "react-redux";
import { formatNumber } from "../../../Utils/lib/formatNumber";
const RiderDetail = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isExit, setisExit] = useState(false);
  const observeEl = useRef();
  const { token } = useSelector((state) => state.user);
  const [stat, setstat] = useState();
  const [rider, setrider] = useState();

  /// Get the exiting element
  const firstElement = useCallback((node) => {
    observeEl.current = new IntersectionObserver((entries) => {
      if (!entries[0].isIntersecting) {
        console.log("not visible");
        setisExit(true);
      } else {
        setisExit(false);
      }
    });

    if (node) observeEl.current.observe(node);
  }, []);

  useEffect(() => {
    async function getSingleRider() {
      await singleRider(id, token)
        .then((res) => {
          console.log(res);
          const { data } = res.data;
          const { statistics, rider } = data;
          setrider(rider);
          setstat(statistics);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getSingleRider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container>
      <div className="w-full h-full">
        <div className="flex justify-start items-center">
          <p className="font-semibold text-lg ">Rider</p>
        </div>
        <div className="py-3 px-6 mb-[50px] flex flex-col bg-white rounded-md  w-full h-fit">
          <RiderHeading
            isExit={isExit}
            firstElement={firstElement}
            name={rider?.fullName || state?.name}
            image={rider?.profileImage?.url}
          />
          <div className="flex py-3  justify-between ">
            <div className="flex flex-col   space-y-3">
              <div className="space-y-2 flex flex-col">
                <span className="font-medium ">Phone Number</span>
                <span className="text-sm">{rider?.phoneNumber}</span>
              </div>
              <div className="space-y-2 flex flex-col">
                <span className="font-medium ">Email</span>
                <span className="text-sm">{rider?.email}</span>
              </div>
              <div className="grid grid-col-1 space-y-2">
                <div className="font-medium ">Address</div>
                <div className="text-sm">
                  Jekaeat street, Honk Kong, Maiduguri{" "}
                </div>
              </div>
              <div className="space-y-2 flex flex-col">
                <span className="font-medium ">Transaction</span>
                <span className="text-sm">{`₦${formatNumber(
                  stat?.revenue
                )}`}</span>
              </div>
            </div>
            {/** */}

            <div className="flex flex-col space-y-3 w-[50%] xl:w-[40%]">
              <div className="space-y-2 flex flex-col">
                <div className="justify-between flex items-center">
                  <span className="font-medium ">
                    Number of Completed Deliveries
                  </span>
                  <button
                    onClick={() => {
                      navigate(`/rider/${id}/completed-deliveries`, {
                        state: {
                          name: state?.name,
                        },
                      });
                    }}
                    className="border border-[#7bb34e] text-sm p-1 text-[13px] text-[#7bb34e] rounded-md flex items-center justify-center space-x-1"
                  >
                    <span>View All</span>
                    <HiOutlineArrowLongRight className="text-[18px]" />
                  </button>
                </div>
                <span>{stat?.completedOrders}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default RiderDetail;
