import React, { useState } from "react";
import profile from "../../../assets/png/customerpic.png";
import { FiEdit3 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { useNavigate, Link } from "react-router-dom";
import EditUser from "../../updateuser/editUser";
import { useDispatch, useSelector } from "react-redux";
import { getRiders } from "../../../Redux/Actions/ActionCreators";
import { deleteUser } from "../../../Utils/api";
import DeleteModal from "../../updateuser/deleteModal";
import moment from "moment";
import { getNairaEquivalent } from "Utils/lib/helper";
const RiderWidget = ({
  createdAt,
  ismore,
  setismore,
  name,
  order,
  delivery,
  phoneNumber,
  revenue,
  userId,
  location,
  id,
}) => {
  const [itemId, setitemId] = useState();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.user);
  const [iseditUser, seteditUser] = useState(false);
  const { riders } = useSelector((state) => state.rider);
  const dispatch = useDispatch();
  const [isdelete, setisdelete] = useState(false);

  const removeUser = async () => {
    setisdelete(!isdelete);
  };
  return (
    <>
      <div className="grid grid-cols-9 pl-[3px] md:pl-5 py-2 border-b gap-5  items-center text-sm ">
        <div>{id}</div>
        <Link
          to={`/rider/${userId}`}
          className="grid grid-cols-6 gap-[60px] items-center col-span-2"
        >
          <div className="w-[50px] h-[50px] rounded-full">
            <img className="w-full h-full rounded-full" src={profile} alt="" />
          </div>
          <div className="flex flex-col space-y-1 justify-start col-span-4">
            <span className="font-medium max-[900px]:w-[120px] lg:w-[120px] xl:w-[150px] text-ellipsis whitespace-nowrap overflow-hidden">
              {name}
            </span>
            <span className="w-[120px] min-[850px]:w-[160px] lg:w-[120px] xl:w-[200px] text-gray-500 text-[13px] text-ellipsis whitespace-nowrap overflow-hidden ">
              {"address --"}
            </span>

            <span className="text-gray-500 text-[13px]">{phoneNumber}</span>
          </div>
        </Link>
        <div>{order}</div>
        <div>{delivery}</div>
        <div>{getNairaEquivalent(revenue)}</div>
        <div>{moment(createdAt).format("ll")}</div>
        <div>{location}</div>

        <div className="flex space-x-1 text-[20px] text-gray-300 cursor-pointer">
          <div
            onClick={() => {
              seteditUser(!iseditUser);
            }}
          >
            <FiEdit3 />
          </div>

          <div
            onClick={() => {
              removeUser();
            }}
          >
            <RiDeleteBin6Line />
          </div>

          <div
            onClick={() => {
              setitemId(id);
              setismore(!ismore);
            }}
            className="relative"
          >
            <BiDotsHorizontalRounded />
            {ismore && itemId === id && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="right-[-50px]  absolute min-w-max"
              >
                {/**attach user id */}
                <span
                  onClick={() => {
                    navigate(`/rider/${userId}`);
                  }}
                  className="text-zinc-700 relative bg-white p-2 rounded-md z-[300] min-w-max text-[13px] shadow-lg "
                >
                  view more
                </span>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setitemId("");
                    setismore(!ismore);
                  }}
                  className="w-full h-full fixed inset-0 z-[40] bg-none"
                ></div>
              </span>
            )}
          </div>
        </div>
      </div>

      <EditUser
        vname={name}
        id={id - 1}
        userId={userId}
        heading={"Edit Rider Details"}
        title={"Rider Title"}
        telephone={"Rider Phone Number"}
        type={"Rider"}
        phoneNumber={phoneNumber}
        iseditUser={iseditUser}
        seteditUser={seteditUser}
      />

      <DeleteModal
        isdelete={isdelete}
        setisdelete={setisdelete}
        token={token}
        id={userId}
        data={riders}
        deleteFn={deleteUser}
        dispatch={dispatch}
        getData={getRiders}
      />
    </>
  );
};

export default RiderWidget;
