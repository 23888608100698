import React, { useState } from "react";
import { SiGooglemessages } from "react-icons/si";

import { BsBellFill, BsChatLeftDots } from "react-icons/bs";
import { Link } from "react-router-dom";
const MessageCard = () => {
  const [ismessage, setismessage] = useState(false);
  return (
    <div className="fixed min-[890px]:hidden z-[33] right-0 top-[200px]">
      <span
        onClick={() => {
          setismessage(false);
        }}
        className={
          ismessage ? "fixed w-full h-full z-[5] inset-0  bg-none" : "hidden"
        }
      ></span>
      <div
        className={` relative rounded-l-3xl w-fit z-[10]   h-[50px] py-4 ${
          ismessage
            ? " px-4 let swipeIn bg-white border-b border-t border-l  shadow-lg"
            : ""
        }`}
      >
        <span className="absolute top-[-2px] right-1 flex h-3 w-3">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
        </span>
        <div className="flex space-x-2 items-center h-full w-full ">
          <div className="flex space-x-5 items-center">
            <div
              className={
                ismessage
                  ? "p-2 bg-[#2D9CDB] bg-opacity-[0.15] relative rounded-md"
                  : "hidden"
              }
            >
              <BsBellFill className="text-[22px] text-[#2D9CDB]" />
              <span className="absolute bg-[#2D9CDB] text-white top-[-6px] right-0 rounded-full px-[8px] text-[10px] flex justify-center items-center">
                <span>1</span>
              </span>
            </div>
            <Link
              to="/feedback"
              className={
                ismessage
                  ? "p-2 bg-[#5E6C93] bg-opacity-[0.15] relative rounded-md"
                  : "hidden"
              }
            >
              <BsChatLeftDots className="text-[22px] text-[#5E6C93]" />
            </Link>
          </div>

          <div
            onClick={() => {
              setismessage(!ismessage);
            }}
            className={
              ismessage
                ? "hidden"
                : "text-[#7ba350] rounded-full bg-white cursor-pointer"
            }
          >
            <SiGooglemessages className="text-[40px] " />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageCard;
