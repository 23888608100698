import React from "react";
import { toast } from "react-hot-toast";

const DeleteMealModal = ({
  isdelete,
  setisdelete,
  data,
  id,
  token,
  dispatch,
  deleteFn,
  mealtype,
}) => {
  const handleDelete = async (e) => {
    e.stopPropagation();
    const meal = mealtype.toLowerCase().split(" ").join("");
    //console.log(mealtype);
    await deleteFn(id, token)
      .then((res) => {
        console.log(res);
        dispatch({
          type: `GET_${meal.toUpperCase()}`,
          payload: data[meal].filter((value) => value._id !== id),
        });
        toast.success("meal deleted successfully");
        setisdelete(!isdelete)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setisdelete(!isdelete);
      }}
      className={
        isdelete
          ? "w-full h-full inset-0 bg-black bg-opacity-10 z-[80] fixed"
          : "hidden"
      }
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="absolute m-auto text-center flex flex-col justify-center inset-0  space-y-16 h-fit w-[300px] p-4 rounded-md bg-white"
      >
        <div className="">Are you sure you want to delete?</div>
        <div className="w-full grid grid-cols-2 items-center ">
          <button
            onClick={(e) => {
              handleDelete(e);
            }}
            className="p-2  focus:bg-gray-200 w-full border-r border-zinc-400"
          >
            Yes
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setisdelete(!isdelete);
            }}
            className="p-2  focus:bg-gray-200 w-full"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteMealModal;
