import { HashRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import { Toaster } from "react-hot-toast";
import Scrolltotop from "./components/UI/ScrollToTop";
import { RequireAuth } from "./Utils/RequireAuth";
import {
  CompletedDeliveries,
  CompletedOrders,
  CustomerBoard,
  Home,
  MealCategory,
  RiderBoard,
  RiderDetail,
  RiderHome,
  Transaction,
  VendorBoard,
  VendorDetail,
  VendorHome,
  Login,
  Payment,
  Feedback,
  Notification,
  Settings,
} from "./Pages";

const App = () => {
  return (
    <div className="w-full h-full text-sm text-[16px]">
      <div>
        <Toaster
          toastOptions={{
            duration: 5000,
            position: "top-center",
            success: {
              style: {
                background: "#222",

                color: "#fff",
              },
            },
            error: {
              duration: 5000,
              position: "top-center",
              style: {
                background: "red",
                color: "#fff",
              },
            },
          }}
        />
      </div>
      <Router>
        <Scrolltotop />
        <Routes>
          {/*    AUTH PAGE */}
          <Route exact path="/" element={<Login />} />
          {/*    LANDING PAGE */}
          {/**Protected routes */}
          <Route
            path="/home"
            element={
              <RequireAuth link={"/"}>
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path="/customer"
            element={
              <RequireAuth link={"/"}>
                <CustomerBoard />
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/vendor"
            element={
              <RequireAuth link={"/"}>
                <VendorHome />
              </RequireAuth>
            }
          >
            <Route
              exact
              index
              element={
                <RequireAuth link={"/"}>
                  <VendorBoard />
                </RequireAuth>
              }
            />
            <Route
              exact
              path=":id"
              element={
                <RequireAuth link={"/"}>
                  <VendorDetail />
                </RequireAuth>
              }
            />
            <Route
              exact
              path=":id/completedorders"
              element={
                <RequireAuth link={"/"}>
                  <CompletedOrders />
                </RequireAuth>
              }
            />
            <Route
              exact
              path=":id/meal-category"
              element={
                <RequireAuth link={"/"}>
                  <MealCategory />
                </RequireAuth>
              }
            />
          </Route>

          <Route
            exact
            path="/transaction"
            element={
              <RequireAuth link={"/"}>
                <Transaction />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/payment"
            element={
              <RequireAuth link={"/"}>
                <Payment />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/feedback"
            element={
              <RequireAuth link={"/"}>
                <Feedback />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/notifications"
            element={
              <RequireAuth link={"/"}>
                <Notification />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/settings"
            element={
              <RequireAuth link={"/"}>
                <Settings />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/rider"
            element={
              <RequireAuth link={"/"}>
                <RiderHome />
              </RequireAuth>
            }
          >
            <Route
              exact
              index
              element={
                <RequireAuth link={"/"}>
                  <RiderBoard />
                </RequireAuth>
              }
            />
            <Route
              exact
              path=":id"
              element={
                <RequireAuth link={"/"}>
                  <RiderDetail />
                </RequireAuth>
              }
            />
            <Route
              exact
              path=":id/completed-deliveries"
              element={
                <RequireAuth link={"/"}>
                  <CompletedDeliveries />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </Router>
    </div>
  );
};

export default App;
