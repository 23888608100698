import { combineReducers } from "redux";
import User from "./user";
import Vendor from "./vendor";
import Customer from "./customer"
import Rider from './rider'
import Meal from "./meal";

const appReducer = combineReducers({
  user: User,
  vendor: Vendor,
  customer: Customer,
  rider:Rider,
  meal:Meal
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    // storage.removeItem('persist:otherKey')
    localStorage.removeItem("persist:root");

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
