import React, { useEffect, useState } from "react";
import Container from "../container/container";
import { getOrderingStatus, toggleOrderingStatus } from "../../Utils/api";
import { useSelector } from "react-redux";
import Loader from "components/UI/Loader";

const Notification = () => {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.user);

  const fetchOrderingStatus = async () => {
    setLoading(true);
    await getOrderingStatus(token)
      .then((res) => {
        const { data } = res.data;
        console.log(data);
        setLoading(false);
        setData(data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const handleToggle = async () => {
    setLoading(true);
    await toggleOrderingStatus(token)
      .then((res) => {
        setLoading(false);
        fetchOrderingStatus();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    fetchOrderingStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <div className="w-full space-y-4 bg-white h-full overflow-y-auto pb-10">
        <div className="w-full rounded-md bg-white px-2 pt-4 text-zinc-700">
          <div className="flex items-center space-x-2 w-full">
            <span className="font-semibold text-lg">Settings</span>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="w-full border-b p-4">
            <h2 className="text-gray-400 font-medium">Ordering Status</h2>
            <div className="my-2 flex justify-between">
              <div
                className={`${
                  !data ? "bg-red-500 " : "bg-primary-100"
                }  rounded-md px-2 py-1 text-white `}
              >
                <div>{data ? <span>Online</span> : <span>Offline</span>}</div>
              </div>
              <div className="text-[8px]">
                <div
                  className={`${
                    data ? "bg-primary-100" : "bg-gray-500"
                  } h-4 w-9  rounded-2xl p-1`}
                >
                  <div
                    onClick={() => {
                      handleToggle();
                    }}
                    className={`${
                      data ? "translate-x-4" : "translate-x-0"
                    } transform duration-300 cursor-pointer h-2 w-3 bg-white rounded-full`}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default Notification;
