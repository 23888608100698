import axios from "axios";

const FetchClient = () => {
  const defaultOptions = {
    // baseURL: "https://jekaeat-main.onrender.com/api",
    // baseURL: 'https://jekaeat-be-e76690e2661a.herokuapp.com/api/',
    baseURL: "https://admin-api.jekaeat.io/v1",
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  return instance;
};

export default FetchClient();
