import React from "react";
import Container from "../container/container";

import BarChart from "./chart/barchart";
import VendorRank from "./vendorrank/vendorRank";
import DashboardCard from "../cards/dashboardCard";
import LatestSales from "./latestsales/latestSales";

const Home = () => {
  return (
    <Container>
      <div className="w-full h-full">
        <p className="font-semibold text-lg ">Dashboard</p>
        <p className="text-sm text-zinc-400">{`Hi mallam, welcome to Jekaeat Admin`}</p>

        <DashboardCard />

        <div className="grid w-full gap-5 grid-cols-2 max-[890px]:grid-cols-1 max-[890px]:gap-10">
          <BarChart />
          <VendorRank />
        </div>
        <LatestSales />
      </div>
    </Container>
  );
};
export default Home;
