import * as type from "../Actions/Types";

const initailState = {
  mainmeal:[],
  sidemeal:[],
  mealpack:[],
  others:[],
};
const Meal = (state = initailState, action) => {
  switch (action.type) {
    case type.GET_MAINMEAL:
      return {
        ...state,
        mainmeal: action.payload,
      };
      case type.GET_SIDEMEAL:
      return {
        ...state,
        sidemeal: action.payload,
      };
      case type.GET_OTHERS:
      return {
        ...state,
        others: action.payload,
      };
      case type.GET_MEALPACK:
      return {
        ...state,
        mealpack: action.payload,
      };


    default:
      return state;
  }
};

export default Meal