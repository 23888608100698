import moment from "moment/moment";
import React from "react";
import { IoIosClose } from "react-icons/io";
import rider from "../../assets/svg/delivered.svg";
import location from "../../assets/svg/location.svg";
import profile from "../../assets/svg/profile.svg";
import { dailyHours } from "../../Utils/lib/stringtoDate";
import { getNairaEquivalent } from "../../Utils/lib/helper";

const OrderDetail = ({
  isdetail,
  setdetail,
  payment,
  restaurant,
  deliveryInfo,
  vendorImg,
  meals,
  createdAt,
  packages,
  discount,
  riderInfo,
  statusUpdate,
  date,
}) => {
  // let priceArray = packages.meals?.map((value) => value.price);
  const total = packages?.reduce(
    (acc, item) => acc + parseFloat(item.total || 0),
    0
  );

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setdetail(!isdetail);
      }}
      className={
        isdetail
          ? "w-full h-full bg-black bg-opacity-50 z-[70] fixed inset-0 flex items-center justify-center"
          : "hidden"
      }
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="w-[600px] h-[90%] bg-gray-100 overflow-hidden relative rounded-b-md"
      >
        <div className="relative w-full h-[20%]">
          <div className="absolute w-full h-full inset-0 bg-black bg-opacity-50">
            <div className="flex justify-between w-full items-center text-white p-3">
              <div className="bg-none h-2 w-2"></div>
              <div>{restaurant}</div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setdetail(!isdetail);
                }}
              >
                <IoIosClose className="text-[22px]" />
              </div>
            </div>
          </div>
          <img
            className="w-full h-full object-cover"
            src={vendorImg || profile}
            alt=""
          />
        </div>
        <div className="w-full flex flex-col  bg-white justify-start text-sm p-[4%] h-[80%] overflow-auto">
          <div className="text-[12px] text-gray-500">
            {moment(createdAt).format("ll")}
          </div>
          {packages?.map((order, idx) => (
            <div className="w-full  border-gray-500 rounded-lg py-2">
              <h2 className="font-bold mb-3">Order {idx + 1}</h2>

              {order.items?.map(({ product, unitPrice, quantity }, idx) => (
                <div
                  key={idx}
                  className="grid grid-cols-3 w-full items-center py-[2%] border-b"
                >
                  <div className="flex space-x-3  items-center">
                    <div className="w-[30px] h-[30px] rounded-full">
                      <img
                        src={product?.image?.url}
                        alt=""
                        className="w-full h-full rounded-full"
                      />
                    </div>
                    <div className="font-medium">
                      {product?.name || product || null}
                    </div>
                  </div>
                  <div className="flex w-full justify-center items-center">
                    <div className="text-[12px] text-gray-500">{`${getNairaEquivalent(
                      unitPrice
                    )}x${quantity}`}</div>
                  </div>
                  <div className="flex w-full justify-end items-center">
                    <div className="font-medium">{`₦${getNairaEquivalent(
                      unitPrice
                    )?.toLocaleString()}`}</div>
                  </div>
                </div>
              ))}
            </div>
          ))}

          <div className="space-y-[2%]">
            <div className="flex justify-between items-center w-full">
              <div className="text-[12px] text-gray-500">Sub Total:</div>
              <div className="font-medium">{`₦${getNairaEquivalent(
                total
              )?.toLocaleString()}`}</div>
            </div>
            <div className="flex justify-between items-center w-full">
              <div className="text-[12px] text-gray-500">Delivery Fee:</div>
              <div className="font-medium">{`₦${getNairaEquivalent(
                deliveryInfo?.fee
              )}`}</div>
            </div>
            <div className="flex justify-between items-center w-full">
              <div className="text-[12px] text-gray-500">Discount:</div>
              <div className="font-medium">{`₦${getNairaEquivalent(
                discount
              )}`}</div>
            </div>
            <div className="flex justify-between items-center w-full">
              <div className="font-semibold text-[15px]">Amount:</div>
              <div className="font-semibold text-[15px]">{`₦${getNairaEquivalent(
                total + deliveryInfo?.fee
              )?.toLocaleString()}`}</div>
            </div>
          </div>
          <div className=" grid py-[2%] mt-[2%] grid-cols-2 w-full">
            <div className="space-y-[2%] ">
              <div className="flex space-x-5 items-center">
                <div className="w-[30px] h-[30px] ">
                  <img src={location} alt="" />
                </div>
                <div className="font-medium text-[12px]">Delivery Address</div>
              </div>

              <div className="grid grid-cols-6 gap-8  items-center">
                <div className="text-[12px] text-gray-500">Name</div>
                <div className="font-medium col-span-5 text-[12px]">{`${
                  deliveryInfo?.contactName?.split(" ")[0] || ""
                }  ${deliveryInfo?.contactName?.split(" ")[1] || ""}`}</div>
              </div>
              <div className="grid grid-cols-6 gap-8 items-center">
                <div className="text-[12px] text-gray-500">Address</div>
                <div className="font-medium col-span-5 text-[12px]">
                  {deliveryInfo?.address?.streetAddress}
                </div>
              </div>
              <div className="grid grid-cols-6 gap-8 items-center">
                <div className="text-[12px] text-gray-500">Phone</div>
                <div className="font-medium  col-span-5 text-[12px]">
                  {deliveryInfo?.contactPhone1}{" "}
                  {deliveryInfo?.contactPhone2 &&
                    `|| ${deliveryInfo?.contactPhone2}`}
                </div>
              </div>
              <div className="grid grid-cols-6 gap-8 items-center">
                <div className="text-[12px] text-gray-500">Note</div>
                <div className="font-medium  col-span-5 text-[12px]">
                  {deliveryInfo?.description ?? "N/A"}
                </div>
              </div>
            </div>
            {/** */}
            <div className="space-y-[2%] ">
              <div className="flex space-x-5 items-center">
                <div className="w-[30px] h-[30px] ">
                  <img src={rider} alt="" />
                </div>
                <div className="font-medium  text-[12px]">Delivered By</div>
              </div>

              <div className="grid grid-cols-6 gap-8  items-center">
                <div className="text-[12px] text-gray-500">Name</div>
                <div className="font-medium col-span-5 text-[12px]">
                  {riderInfo?.fullName ?? ""}
                </div>
              </div>
              <div className="flex space-x-2  items-center">
                <div className="text-[12px] text-gray-500">Created:</div>
                <div className="font-medium col-span-5 text-[12px]">{date}</div>
              </div>
              <div className=" gap-8  items-center">
                <div className="flex flex-col">
                  {statusUpdate?.map((item, id) => {
                    return (
                      <div
                        key={id}
                        className="flex space-x-2 items-center  text-[12px]"
                      >
                        <div className="text-gray-500">{item.status}: </div>
                        <div className="font-medium">
                          {dailyHours(item.updatedAt)}{" "}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-3 bg-gray-100 "></div>
          <div className=" w-full text-[13px] space-y-2">
            <div className="font-semibold">Payment</div>
            <div className="w-full flex justify-between items-center">
              <span className="text-gray-600 capitalize ">Payment Method:</span>
              <div className="font-medium capitalize">
                {payment?.method || ""}
              </div>
            </div>
            <div className="w-full flex justify-between items-center">
              <span className="text-gray-600 capitalize">
                Payment Reference:
              </span>
              <div className="font-medium capitalize">
                {payment?.reference || ""}
              </div>
            </div>
            <div className="w-full flex justify-between items-center">
              <span className="text-gray-600 capitalize">Payment Status:</span>
              <div className="font-medium capitalize">
                {payment?.status || ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
