import React, { useState } from "react";
import toast from "react-hot-toast";
import { AiFillCloseCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { sheduleNotification } from "Utils/api";
import validateFormData from "Utils/lib/helper";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Loader from "./Loader";

const animatedComponents = makeAnimated();

const daysOfWeek = [
  { label: "Sunday", value: 0 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
];

function CreateBroadcast({ handleClose, getNotification }) {
  const [name, setName] = useState("");
  const [receiver, setReceiver] = useState("Customer");
  const [time, setTime] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [days, setDays] = useState([]);
  const [loading, setLoading] = useState(false);

  function handleChange(selectedOption) {
    console.log(selectedOption);
    const selectedDays = [];
    selectedOption.forEach((day) => {
      selectedDays.push(day.value);
    });
    setDays(selectedDays);
    // setSelectedOptions(selectedOption);
  }
  const { token } = useSelector((state) => state.user);

  function handleSheduleNotification() {
    const payload = {
      name,
      time,
      days,
      userType: receiver,
      notification: {
        title,
        body,
      },
    };
    console.log(payload);
    if (!validateFormData(toast, payload)) {
      return;
    }

    setLoading(true);
    sheduleNotification(payload, token)
      .then((res) => {
        setLoading(false);
        toast.success("Notification scheduled successfully");
        handleClose();
        getNotification();
      })
      .catch((err) => {
        setLoading(false);
        handleClose();
      });
  }

  return (
    <div className="inset-0 fixed bg-[#000]/50 z-[99999] p-2 grid place-content-center">
      <div className=" bg-white p-4 md:min-w-[800px] sm:min-w-[400px] w-full rounded-lg">
        <div className="flex justify-end">
          <button
            onClick={() => {
              handleClose();
            }}
          >
            <AiFillCloseCircle />
          </button>
        </div>
        <div className="flex justify-center">
          <h2 className="font-medium text-xl">Create Broadcast</h2>
        </div>

        <div className="grid md:grid-cols-2 gap-8 mt-8">
          <div>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              className=" mt-2 block form__input input-field border focus:border-gray-500 hover:border-gray-500 rounded-md focus:outline-none w-full h-11 px-4"
            />
          </div>
          <div>
            <label htmlFor="userType">To:</label>
            <select
              name="userType"
              id="userType"
              onChange={(e) => {
                setReceiver(e.target.value);
              }}
              className=" mt-2 block form__input input-field border focus:border-gray-500 hover:border-gray-500 rounded-md focus:outline-none w-full h-11 px-2"
            >
              <option value="Customer">Customer</option>
              <option value="Vendor">Vendor</option>
              <option value="Rider">Rider</option>
            </select>
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-8 mt-4">
          <div>
            <label htmlFor="Time">Time</label>
            <input
              type="time"
              id="Time"
              value={time}
              onChange={(e) => {
                setTime(e.target.value);
              }}
              className=" mt-2 block form__input input-field border focus:border-gray-500 hover:border-gray-500 rounded-md focus:outline-none w-full h-11 px-4"
            />
          </div>
          <div>
            <label htmlFor="userType">Days:</label>
            <Select
              className="mt-2"
              closeMenuOnSelect={false}
              components={animatedComponents}
              defaultValue={[daysOfWeek[0], daysOfWeek[5]]}
              isMulti
              isSearchable
              options={daysOfWeek}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-8 mt-4">
          <div>
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              className=" mt-2 block form__input input-field border focus:border-gray-500 hover:border-gray-500 rounded-md focus:outline-none w-full h-11 px-4"
            />
          </div>
          <div>
            <label htmlFor="body">Message(Body):</label>
            <input
              type="text"
              id="body"
              value={body}
              onChange={(e) => {
                setBody(e.target.value);
              }}
              className=" mt-2 block form__input input-field border focus:border-gray-500 hover:border-gray-500 rounded-md focus:outline-none w-full h-11 px-4"
            />
          </div>
        </div>
        <div className="flex py-5 justify-center items-center">
          <button
            onClick={() => {
              handleSheduleNotification();
            }}
            className="font-medium text-white bg-[#7bb43e] py-2 rounded-md min-w-max px-4"
          >
            {loading ? <Loader /> : <span>Schedule</span>}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateBroadcast;
