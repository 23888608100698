import React, { useState, useEffect, useRef } from "react";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { useSelector } from "react-redux";
import { getVendorStats } from "../../Utils/api";
const VendorCards = () => {
  const { token } = useSelector((state) => state.user);
  const slide = useRef();
  const [isprev, setisprev] = useState(false);
  const [isnext, setisnext] = useState(true);
  const [data, setdata] = useState();

  //console.log(token)

  useEffect(() => {
    async function getStats() {
      await getVendorStats(token)
        .then((res) => {
          const { data } = res.data;
          console.log(data);
          setdata(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function prev() {
    // e.stopPropagation()

    //console.log('window.scrollWidth')
    console.log(slide.current.scrollLeft);
    console.log(slide.current.scrollWidth);
    console.log(slide.current.offsetWidth);
    slide.current.scrollBy({
      left: -150,
      behavior: "smooth",
    });
  }

  function next() {
    //e.stopPropagation()
    //console.log('window.scrollWidth')

    console.log(slide.current.scrollWidth);
    console.log(slide.current.offsetWidth);
    slide.current.scrollBy({
      left: 150,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    function scrollEl() {
      console.log("Slide");
      if (slide.current.scrollLeft === 0) {
        setisprev(false);
      } else {
        setisprev(true);
      }

      if (
        slide.current.scrollLeft + slide.current.offsetWidth >=
        slide.current.scrollWidth
      ) {
        setisnext(false);
      } else {
        setisnext(true);
      }
    }

    slide.current?.addEventListener("scroll", scrollEl);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => slide.current?.removeEventListener("scroll", scrollEl);
  }, [slide.current?.scrollLeft]);

  return (
    <div className="w-full  py-4 overflow-hidden relative">
      <div
        onClick={prev}
        className={
          isprev
            ? "absolute left-0 top-[40%] z-[20] text-[25px] p-1 rounded-full bg-white text-[#7bb43e]"
            : "hidden"
        }
      >
        <MdNavigateBefore />
      </div>
      <div
        onClick={next}
        className={
          isnext
            ? "absolute right-0 top-[40%] z-[20] text-[25px] p-1 rounded-full bg-white text-[#7bb43e]"
            : "hidden"
        }
      >
        <MdNavigateNext />
      </div>
      <div ref={slide} className="w-full h-full overflow-x-auto hide-scroll ">
        <div className="min-w-max h-full flex items-center gap-5 ">
          <div className="w-[240px] h-[150px] flex items-center justify-center space-x-3  rounded-lg bg-[#EEFDFE] px-3 py-4">
            <div className="flex flex-col justify-center items-center space-y-3">
              <div className="font-medium text-2xl">{data?.totalUsers}</div>
              <div>Total Number of Vendors</div>
              <div className="text-[12px] text-gray-400">{`${data?.percentageChange.toFixed(
                0
              )}% increase from last month`}</div>
            </div>
          </div>

          <div className="w-[240px] h-[150px] flex items-center justify-center space-x-3 rounded-lg bg-[#FFEBEB] px-3 py-4">
            <div className="flex flex-col justify-center items-center space-y-3">
              <div className="font-medium text-2xl">
                {data?.currentMonthUsers}
              </div>
              <div>Total Number of New Vendors</div>
              <div className="text-[12px] text-gray-400">
                New vendors added this month
              </div>
            </div>
          </div>
          <div className="w-[240px] h-[150px] flex items-center justify-center space-x-3 rounded-lg bg-[#F5EDFF] px-3 py-4">
            <div className="flex flex-col justify-center items-center space-y-3">
              <div className="font-medium text-2xl">{`0`}</div>
              <div>Total Number of Sales</div>
              <div className="text-[12px] text-gray-400">
                2% increase from last month
              </div>
            </div>
          </div>
          <div className="w-[240px] h-[150px] flex items-center justify-center space-x-3 rounded-lg bg-[#EEFDFE] px-3 py-4">
            <div className="flex flex-col justify-center items-center space-y-3">
              <div className="font-medium text-2xl">{`0`}</div>
              <div>Last Week Sales</div>
              <div className="text-[12px] text-gray-400">
                2% increase from last week
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorCards;
