import React from "react";

import Container from "../container/container";

import RidersList from "./riderslist/ridersList";

const RiderBoard = () => {
  return (
    <Container>
      <div className="w-full h-full">
        <div className="flex justify-start mb-3 items-center">
          <p className="font-semibold text-lg ">Rider</p>
        </div>
        <RidersList />
      </div>
    </Container>
  );
};

export default RiderBoard;
